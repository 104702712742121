// [SignUpCodeUser]
// String id;
// DateTime createAt;
// String name;
// String phone;
// String bankName;
// String accountNumber;
// String accountOwner;
// bool tax;
// String taxResidentNumber;
// String taxCompany;
// String taxBusinessNumber;
// String taxOwner;
// String taxUptae;
// String taxJongmok;
// String taxAddress;
// String taxEmail;
// String taxManager;
// String taxPhone;

// [SignUpCodeChannel]
// String id;
// DateTime createAt;
// String name;
// int viewCount;
// String signUpCodeUserId;
// String url;
// String qrUrl;
// String signUpCode;

// [Hospital]
// String id;
// DateTime createAt;
// String hospitalCode;
// String hospitalCodeUrl;
// String hospitalCodeQrUrl;
// List<dynamic> tagList;
// int numOfFavorites;
// String name;
// bool availableVideoCall;
// bool availableAudioCall;
// String address;
// String addressDetail;
// double gpsX;
// double gpsY;
// String phone;
// String fax;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool availableAtHoliday;
// List<dynamic> hospitalImages;
// List<dynamic> availableMedicalSubjects;
// String availableMedicalSubject;
// String businessLicenseFileUrl;
// String accountBankName;
// String accountNumber;
// String accountOwnerName;
// String invoiceEmail;
// any subscriptionPlan;
// String subscriptionCardType;
// String subscriptionCardCompany;
// String subscriptionCardOwnerName;
// String subscriptionCardNumber;
// String subscriptionCardValidDate;
// String subscriptionCardCvc;
// bool isReservationAllTime;
// DateTime reservationOpenAt;
// DateTime reservationCloseAt;
// DateTime reservationOpenAt2;
// DateTime reservationCloseAt2;
// int reservationInvervalMinutes;
// List<dynamic> reservationAvailableDoctorIds;
// String settlementPersonName;
// String settlementPersonPhone;
// String businessNumber;
// DateTime mondayLunchStartAt;
// DateTime tuesdayLunchStartAt;
// DateTime wednesdayLunchStartAt;
// DateTime thursdayLunchStartAt;
// DateTime fridayLunchStartAt;
// DateTime saturdayLunchStartAt;
// DateTime sundayLunchStartAt;
// DateTime mondayLunchEndAt;
// DateTime tuesdayLunchEndAt;
// DateTime wednesdayLunchEndAt;
// DateTime thursdayLunchEndAt;
// DateTime fridayLunchEndAt;
// DateTime saturdayLunchEndAt;
// DateTime sundayLunchEndAt;
// String postCode;
// String phone1;
// String phone2;
// String phone3;
// String fax1;
// String fax2;
// String fax3;
// String settlementPersonPhone1;
// String settlementPersonPhone2;
// String settlementPersonPhone3;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// any operatingTimes;
// any holidayWeekDays;
// bool quit;
// any whyQuit;
// DateTime quitDate;
// DateTime planUntilDate;
// String approveState;
// DateTime subscriptionStartAt;
// int subscriptionNumOfAdditionalPeople;
// bool isAddressJibun;
// bool subscriptionPayment;
// String hospitalNumber;
// bool enableAutoPay;
// bool enableAutoAccept;
// int nextClinicNotificationDeadline;
// bool minMilageSubscription;
// int autoPurchaseMileages;
// int minMileages;
// int pushMileages;
// String billingKey;
// String cardName;
// String cardNumber;
// bool notSupportReservation;
// String hospitalType;

// [Certificate]
// String id;
// DateTime createAt;
// String certificateName;
// bool isAttached;
// String attachmentUrl;
// int nextClinicNotificationDeadline;

// [AdminNotice]
// String id;
// DateTime createAt;
// AdminNoticeTarget adminNoticeTarget;
// String title;
// String content;

// [Qna]
// String id;
// DateTime createAt;
// QuestionerType questionerType;
// any appUserId;
// any appUserName;
// any hospitalId;
// any hospitalName;
// any hospitalWorkerId;
// any hospitalWorkerName;
// String pharmacyId;
// String pharmacyName;
// any pharmacyWorkerId;
// any pharmacyWorkerName;
// String category;
// String title;
// String content;
// List<dynamic> questionAttachmentUrls;
// bool isReplied;
// String reply;
// DateTime replyAt;

// [NonBenefit]
// String id;
// DateTime createAt;
// String type;
// String name;
// int price;
// bool isAttached;
// String fileUrl;
// int orderPriority;
// List<dynamic> descriptionFileUrlList;
// String descriptionText;

// [HospitalNotice]
// String id;
// String hospitalId;
// String authorId;
// String authorName;
// String authorTitle;
// DateTime createAt;
// String header;
// bool isMainNotice;
// String title;
// String content;
// List<dynamic> attachmentUrls;

// [HospitalSubscription]
// DateTime createAt;
// dynamic plan;
// String hospitalWorkerId;
// String hospitalId;
// int paymentDurationMonth;
// String payerName;
// String payerPhone;
// bool active;
// String billingKey;
// String cardName;
// String cardNumber;
// List<dynamic> coupons;

// [HospitalPayment]
// String id;
// DateTime createAt;
// dynamic subscription;
// DateTime scheduledAt;
// bool paid;
// DateTime payAt;
// bool error;
// String errorMessage;
// String errorCode;
// String hospitalId;
// String hospitalWorkerId;
// String subscriptionId;
// bool cancel;

// [Payment]
// String id;
// DateTime createAt;
// String billingKey;
// String name;
// String amount;
// String payerName;
// String payerPhone;
// bool paid;
// DateTime payAt;
// bool error;
// String errorMessage;
// String errorCode;

// [MembershipUsage]
// String id;
// String hospitalId;
// DateTime month;
// int normalClinicCount;
// int audioClinicCount;
// int videoClinicCount;
// int normalClinicSeconds;
// int audioClinicSeconds;
// int videoClinicSeconds;
// int pushCount;

// [HospitalWorkUpdateLog]
// String id;
// DateTime createAt;
// String doctorMemo;
// int nextScheduleAfter;
// String nextScheduleDurationUnit;
// DateTime nextClinicDateTime;
// String nextScheduleDescription;
// String hospitalWorkerId;

// [HospitalWork]
// String id;
// DateTime createAt;
// DateTime reservationDateTime;
// DateTime callStartTime;
// DateTime callEndTime;
// ClinicState clinicState;
// String patientId;
// String patientName;
// String patientAddress;
// String patientPhone;
// String patientResidentNumber;
// String patientGender;
// DateTime patientBirth;
// String medicationInUse;
// String allergy;
// any diseases;
// bool isSecondTimeClinic;
// bool isVideoClinic;
// String symptomDescription;
// List<dynamic> images;
// List<dynamic> requestCertificates;
// String hospitalId;
// String hospitalName;
// String doctorId;
// String doctorName;
// String patientUserMemo;
// int waitingTimeMinutes;
// String doctorMemo;
// int priceBenefit;
// int priceNonBenefit;
// int priceDelivery;
// String prescriptionFileUrl;
// String prescriptionBriefDescription;
// bool isNextScheduleRequired;
// int nextScheduleAfter;
// String nextScheduleDurationUnit;
// int nextClinicDate;
// String nextScheduleDescription;
// String videoRecordFileUrl;
// String audioRecordFileUrl;
// DateTime payAt;
// String pharmacyId;
// String pharmacyWorkId;
// String pharmacyName;
// bool isSettled;
// DateTime settledAt;
// int settleAmount;
// int paymentAmount;
// any paymentResult;

// [ReservedPushNotification]
// String id;
// DateTime createAt;
// DateTime reservedAt;
// bool sended;
// DateTime sendedAt;
// String title;
// String content;
// String link;
// String senderType;
// String senderId;
// String senderName;
// List<dynamic> target;
// String receiverType;
// List<dynamic> receiverIds;
// List<dynamic> receivedAppUserIds;
// List<dynamic> receivedHospitalUserIds;
// List<dynamic> receivedPharmacyUserIds;
// List<dynamic> sendMethods;
// int spentMileages;
// String hospitalWorkId;

// [HospitalMileagePayment]
// String id;
// DateTime createAt;
// bool autoPurchase;
// int amount;
// dynamic paymentResult;
// int mileage;
// String hospitalId;
// bool paid;
// String errorMessage;
// DateTime payAt;

// [TempHoliday]
// String id;
// DateTime createAt;
// String hospitalId;
// String pharmacyId;
// String hospitalWorkerId;
// DateTime date;
// String name;

// [Pharmacy]
// String id;
// DateTime createAt;
// String accountId;
// String accountUserName;
// DateTime accountUserBirth;
// String accountUserPhone;
// String accountUserEmail;
// String pharmacistLicenseImageUrl;
// int numOfFavorites;
// String name;
// String address;
// String addressDetail;
// double gpsX;
// double gpsY;
// String phone;
// String fax;
// bool mondayEnable;
// bool tuesdayEnable;
// bool wednesdayEnable;
// bool thursdayEnable;
// bool fridayEnable;
// bool saturdayEnable;
// bool sundayEnable;
// DateTime mondayOpenAt;
// DateTime tuesdayOpenAt;
// DateTime wednesdayOpenAt;
// DateTime thursdayOpenAt;
// DateTime fridayOpenAt;
// DateTime saturdayOpenAt;
// DateTime sundayOpenAt;
// DateTime mondayCloseAt;
// DateTime tuesdayCloseAt;
// DateTime wednesdayCloseAt;
// DateTime thursdayCloseAt;
// DateTime fridayCloseAt;
// DateTime saturdayCloseAt;
// DateTime sundayCloseAt;
// bool availableAtHoliday;
// List<dynamic> pharmacyImages;
// String businessLicenseFileUrl;
// String accountBankName;
// String accountNumber;
// String accountOwnerName;
// String invoiceEmail;
// List<dynamic> tagList;
// String businessNumber;
// String settlementPersonName;
// String settlementPersonPhone;
// DateTime mondayLunchStartAt;
// DateTime tuesdayLunchStartAt;
// DateTime wednesdayLunchStartAt;
// DateTime thursdayLunchStartAt;
// DateTime fridayLunchStartAt;
// DateTime saturdayLunchStartAt;
// DateTime sundayLunchStartAt;
// DateTime mondayLunchEndAt;
// DateTime tuesdayLunchEndAt;
// DateTime wednesdayLunchEndAt;
// DateTime thursdayLunchEndAt;
// DateTime fridayLunchEndAt;
// DateTime saturdayLunchEndAt;
// DateTime sundayLunchEndAt;
// List<dynamic> operatingTimes;
// bool mondayLunch;
// bool tuesdayLunch;
// bool wednesdayLunch;
// bool thursdayLunch;
// bool fridayLunch;
// bool saturdayLunch;
// bool sundayLunch;
// String postCode;
// List<dynamic> holidayWeekDays;
// String phone1;
// String phone2;
// String phone3;
// String fax1;
// String fax2;
// String fax3;
// String settlementPersonPhone1;
// String settlementPersonPhone2;
// String settlementPersonPhone3;
// String accountUserPhone1;
// String accountUserPhone2;
// String accountUserPhone3;
// bool quit;
// any whyQuit;
// String approveState;
// bool kakaoTalkIdNotification;
// String kakaoTalkId;
// bool shopOpened;
// bool isAddressJibun;
// String gender;
// String pharmacyNumber;
// bool enableInDay;
// bool enableQuick;
// bool enableMail;

// [RelatedPharmacy]
// String id;
// DateTime createAt;
// String pharmacyId;
// String name;
// String phone;
// String address;
// String addressDetail;
// int order;

// [Document]
// String id;
// DateTime createAt;
// String title;
// List<dynamic> fileUrls;
// String hospitalWorkId;
// String hospitalName;
// DocumentType documentType;

// [PartnerPointUsage]
// String id;
// DateTime createAt;
// String usageType;
// String partnerId;
// String partnerName;
// String hospitalId;
// String hospitalName;
// String description;
// List<dynamic> months;
// int amount;
// String memo;
// DateTime transferDate;

// [PartnerPointTransfer]
// String id;
// DateTime createAt;
// String partnerId;
// String partnerName;
// int amount;
// int transferAmount;
// String bankName;
// String accountNumber;
// String accountOwner;
// String state;
// DateTime transferDate;
// bool tax;
// String taxResidentNumber;
// String taxCompany;
// String taxBusinessNumber;
// String taxOwner;
// String taxUptae;
// String taxJongmok;
// String taxAddress;
// String taxEmail;
// String taxManager;
// String taxPhone;


/* eslint-disable no-unused-vars */
import Vue from "vue";
export default {
};

function isNull(text) {
	if ((typeof text == "undefined") || (text == null)) {
		return true;
	}
	else {
		return false;
	}
}

function toNumber(text) {
	if (isNull(text)) {
		return null;
	}
	else {
		return text * 1;
	}
}

function toText(number) {
	if (isNull(number)) {
		return null;
	}
	else {
		return number + '';
	}
}

function toSafeValue(value) {
	if (isNull(value)) {
		return null;
	}
	else {
		return value;
	}
}

export const models = {
	install(Vue) {
		Vue.prototype.$models = {
			SignUpCodeUserModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						phone: '',
						bankName: '',
						accountNumber: '',
						accountOwner: '',
						tax: null,
						taxResidentNumber: '',
						taxCompany: '',
						taxBusinessNumber: '',
						taxOwner: '',
						taxUptae: '',
						taxJongmok: '',
						taxAddress: '',
						taxEmail: '',
						taxManager: '',
						taxPhone: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						phone: toText(model.phone),
						bankName: toText(model.bankName),
						accountNumber: toText(model.accountNumber),
						accountOwner: toText(model.accountOwner),
						tax: toSafeValue(model.tax),
						taxResidentNumber: toText(model.taxResidentNumber),
						taxCompany: toText(model.taxCompany),
						taxBusinessNumber: toText(model.taxBusinessNumber),
						taxOwner: toText(model.taxOwner),
						taxUptae: toText(model.taxUptae),
						taxJongmok: toText(model.taxJongmok),
						taxAddress: toText(model.taxAddress),
						taxEmail: toText(model.taxEmail),
						taxManager: toText(model.taxManager),
						taxPhone: toText(model.taxPhone),
					};
				},
			},

			SignUpCodeChannelModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						name: '',
						viewCount: 0,
						signUpCodeUserId: '',
						url: '',
						qrUrl: '',
						signUpCode: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						name: toText(model.name),
						viewCount: toNumber(model.viewCount),
						signUpCodeUserId: toText(model.signUpCodeUserId),
						url: toText(model.url),
						qrUrl: toText(model.qrUrl),
						signUpCode: toText(model.signUpCode),
					};
				},
			},

			HospitalModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalCode: '',
						hospitalCodeUrl: '',
						hospitalCodeQrUrl: '',
						tagList: null,
						numOfFavorites: 0,
						name: '',
						availableVideoCall: null,
						availableAudioCall: null,
						address: '',
						addressDetail: '',
						gpsX: 0,
						gpsY: 0,
						phone: '',
						fax: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						availableAtHoliday: null,
						hospitalImages: null,
						availableMedicalSubjects: null,
						availableMedicalSubject: '',
						businessLicenseFileUrl: '',
						accountBankName: '',
						accountNumber: '',
						accountOwnerName: '',
						invoiceEmail: '',
						subscriptionPlan: null,
						subscriptionCardType: '',
						subscriptionCardCompany: '',
						subscriptionCardOwnerName: '',
						subscriptionCardNumber: '',
						subscriptionCardValidDate: '',
						subscriptionCardCvc: '',
						isReservationAllTime: null,
						reservationOpenAt: null,
						reservationCloseAt: null,
						reservationOpenAt2: null,
						reservationCloseAt2: null,
						reservationInvervalMinutes: 0,
						reservationAvailableDoctorIds: null,
						settlementPersonName: '',
						settlementPersonPhone: '',
						businessNumber: '',
						mondayLunchStartAt: null,
						tuesdayLunchStartAt: null,
						wednesdayLunchStartAt: null,
						thursdayLunchStartAt: null,
						fridayLunchStartAt: null,
						saturdayLunchStartAt: null,
						sundayLunchStartAt: null,
						mondayLunchEndAt: null,
						tuesdayLunchEndAt: null,
						wednesdayLunchEndAt: null,
						thursdayLunchEndAt: null,
						fridayLunchEndAt: null,
						saturdayLunchEndAt: null,
						sundayLunchEndAt: null,
						postCode: '',
						phone1: '',
						phone2: '',
						phone3: '',
						fax1: '',
						fax2: '',
						fax3: '',
						settlementPersonPhone1: '',
						settlementPersonPhone2: '',
						settlementPersonPhone3: '',
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						operatingTimes: null,
						holidayWeekDays: null,
						quit: null,
						whyQuit: null,
						quitDate: null,
						planUntilDate: null,
						approveState: '',
						subscriptionStartAt: null,
						subscriptionNumOfAdditionalPeople: 0,
						isAddressJibun: null,
						subscriptionPayment: null,
						hospitalNumber: '',
						enableAutoPay: null,
						enableAutoAccept: null,
						nextClinicNotificationDeadline: 0,
						minMilageSubscription: null,
						autoPurchaseMileages: 0,
						minMileages: 0,
						pushMileages: 0,
						billingKey: '',
						cardName: '',
						cardNumber: '',
						notSupportReservation: null,
						hospitalType: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalCode: toText(model.hospitalCode),
						hospitalCodeUrl: toText(model.hospitalCodeUrl),
						hospitalCodeQrUrl: toText(model.hospitalCodeQrUrl),
						tagList: toSafeValue(model.tagList),
						numOfFavorites: toNumber(model.numOfFavorites),
						name: toText(model.name),
						availableVideoCall: toSafeValue(model.availableVideoCall),
						availableAudioCall: toSafeValue(model.availableAudioCall),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						gpsX: toNumber(model.gpsX),
						gpsY: toNumber(model.gpsY),
						phone: toText(model.phone),
						fax: toText(model.fax),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						hospitalImages: toSafeValue(model.hospitalImages),
						availableMedicalSubjects: toSafeValue(model.availableMedicalSubjects),
						availableMedicalSubject: toText(model.availableMedicalSubject),
						businessLicenseFileUrl: toText(model.businessLicenseFileUrl),
						accountBankName: toText(model.accountBankName),
						accountNumber: toText(model.accountNumber),
						accountOwnerName: toText(model.accountOwnerName),
						invoiceEmail: toText(model.invoiceEmail),
						subscriptionPlan: toSafeValue(model.subscriptionPlan),
						subscriptionCardType: toText(model.subscriptionCardType),
						subscriptionCardCompany: toText(model.subscriptionCardCompany),
						subscriptionCardOwnerName: toText(model.subscriptionCardOwnerName),
						subscriptionCardNumber: toText(model.subscriptionCardNumber),
						subscriptionCardValidDate: toText(model.subscriptionCardValidDate),
						subscriptionCardCvc: toText(model.subscriptionCardCvc),
						isReservationAllTime: toSafeValue(model.isReservationAllTime),
						reservationOpenAt: toSafeValue(model.reservationOpenAt),
						reservationCloseAt: toSafeValue(model.reservationCloseAt),
						reservationOpenAt2: toSafeValue(model.reservationOpenAt2),
						reservationCloseAt2: toSafeValue(model.reservationCloseAt2),
						reservationInvervalMinutes: toNumber(model.reservationInvervalMinutes),
						reservationAvailableDoctorIds: toSafeValue(model.reservationAvailableDoctorIds),
						settlementPersonName: toText(model.settlementPersonName),
						settlementPersonPhone: toText(model.settlementPersonPhone),
						businessNumber: toText(model.businessNumber),
						mondayLunchStartAt: toSafeValue(model.mondayLunchStartAt),
						tuesdayLunchStartAt: toSafeValue(model.tuesdayLunchStartAt),
						wednesdayLunchStartAt: toSafeValue(model.wednesdayLunchStartAt),
						thursdayLunchStartAt: toSafeValue(model.thursdayLunchStartAt),
						fridayLunchStartAt: toSafeValue(model.fridayLunchStartAt),
						saturdayLunchStartAt: toSafeValue(model.saturdayLunchStartAt),
						sundayLunchStartAt: toSafeValue(model.sundayLunchStartAt),
						mondayLunchEndAt: toSafeValue(model.mondayLunchEndAt),
						tuesdayLunchEndAt: toSafeValue(model.tuesdayLunchEndAt),
						wednesdayLunchEndAt: toSafeValue(model.wednesdayLunchEndAt),
						thursdayLunchEndAt: toSafeValue(model.thursdayLunchEndAt),
						fridayLunchEndAt: toSafeValue(model.fridayLunchEndAt),
						saturdayLunchEndAt: toSafeValue(model.saturdayLunchEndAt),
						sundayLunchEndAt: toSafeValue(model.sundayLunchEndAt),
						postCode: toText(model.postCode),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						fax1: toText(model.fax1),
						fax2: toText(model.fax2),
						fax3: toText(model.fax3),
						settlementPersonPhone1: toText(model.settlementPersonPhone1),
						settlementPersonPhone2: toText(model.settlementPersonPhone2),
						settlementPersonPhone3: toText(model.settlementPersonPhone3),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						operatingTimes: toSafeValue(model.operatingTimes),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						quit: toSafeValue(model.quit),
						whyQuit: toSafeValue(model.whyQuit),
						quitDate: toSafeValue(model.quitDate),
						planUntilDate: toSafeValue(model.planUntilDate),
						approveState: toText(model.approveState),
						subscriptionStartAt: toSafeValue(model.subscriptionStartAt),
						subscriptionNumOfAdditionalPeople: toNumber(model.subscriptionNumOfAdditionalPeople),
						isAddressJibun: toSafeValue(model.isAddressJibun),
						subscriptionPayment: toSafeValue(model.subscriptionPayment),
						hospitalNumber: toText(model.hospitalNumber),
						enableAutoPay: toSafeValue(model.enableAutoPay),
						enableAutoAccept: toSafeValue(model.enableAutoAccept),
						nextClinicNotificationDeadline: toNumber(model.nextClinicNotificationDeadline),
						minMilageSubscription: toSafeValue(model.minMilageSubscription),
						autoPurchaseMileages: toNumber(model.autoPurchaseMileages),
						minMileages: toNumber(model.minMileages),
						pushMileages: toNumber(model.pushMileages),
						billingKey: toText(model.billingKey),
						cardName: toText(model.cardName),
						cardNumber: toText(model.cardNumber),
						notSupportReservation: toSafeValue(model.notSupportReservation),
						hospitalType: toText(model.hospitalType),
					};
				},
			},

			CertificateModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						certificateName: '',
						isAttached: null,
						attachmentUrl: '',
						nextClinicNotificationDeadline: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						certificateName: toText(model.certificateName),
						isAttached: toSafeValue(model.isAttached),
						attachmentUrl: toText(model.attachmentUrl),
						nextClinicNotificationDeadline: toNumber(model.nextClinicNotificationDeadline),
					};
				},
			},

			AdminNoticeModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						adminNoticeTarget: null,
						title: '',
						content: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						adminNoticeTarget: toSafeValue(model.adminNoticeTarget),
						title: toText(model.title),
						content: toText(model.content),
					};
				},
			},

			QnaModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						questionerType: null,
						appUserId: null,
						appUserName: null,
						hospitalId: null,
						hospitalName: null,
						hospitalWorkerId: null,
						hospitalWorkerName: null,
						pharmacyId: '',
						pharmacyName: '',
						pharmacyWorkerId: null,
						pharmacyWorkerName: null,
						category: '',
						title: '',
						content: '',
						questionAttachmentUrls: null,
						isReplied: null,
						reply: '',
						replyAt: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						questionerType: toSafeValue(model.questionerType),
						appUserId: toSafeValue(model.appUserId),
						appUserName: toSafeValue(model.appUserName),
						hospitalId: toSafeValue(model.hospitalId),
						hospitalName: toSafeValue(model.hospitalName),
						hospitalWorkerId: toSafeValue(model.hospitalWorkerId),
						hospitalWorkerName: toSafeValue(model.hospitalWorkerName),
						pharmacyId: toText(model.pharmacyId),
						pharmacyName: toText(model.pharmacyName),
						pharmacyWorkerId: toSafeValue(model.pharmacyWorkerId),
						pharmacyWorkerName: toSafeValue(model.pharmacyWorkerName),
						category: toText(model.category),
						title: toText(model.title),
						content: toText(model.content),
						questionAttachmentUrls: toSafeValue(model.questionAttachmentUrls),
						isReplied: toSafeValue(model.isReplied),
						reply: toText(model.reply),
						replyAt: toSafeValue(model.replyAt),
					};
				},
			},

			NonBenefitModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						type: '',
						name: '',
						price: 0,
						isAttached: null,
						fileUrl: '',
						orderPriority: 0,
						descriptionFileUrlList: null,
						descriptionText: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						type: toText(model.type),
						name: toText(model.name),
						price: toNumber(model.price),
						isAttached: toSafeValue(model.isAttached),
						fileUrl: toText(model.fileUrl),
						orderPriority: toNumber(model.orderPriority),
						descriptionFileUrlList: toSafeValue(model.descriptionFileUrlList),
						descriptionText: toText(model.descriptionText),
					};
				},
			},

			HospitalNoticeModel: {
				create: function () {
					return {
						id: '',
						hospitalId: '',
						authorId: '',
						authorName: '',
						authorTitle: '',
						createAt: null,
						header: '',
						isMainNotice: null,
						title: '',
						content: '',
						attachmentUrls: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						hospitalId: toText(model.hospitalId),
						authorId: toText(model.authorId),
						authorName: toText(model.authorName),
						authorTitle: toText(model.authorTitle),
						createAt: toSafeValue(model.createAt),
						header: toText(model.header),
						isMainNotice: toSafeValue(model.isMainNotice),
						title: toText(model.title),
						content: toText(model.content),
						attachmentUrls: toSafeValue(model.attachmentUrls),
					};
				},
			},

			HospitalSubscriptionModel: {
				create: function () {
					return {
						createAt: null,
						plan: null,
						hospitalWorkerId: '',
						hospitalId: '',
						paymentDurationMonth: 0,
						payerName: '',
						payerPhone: '',
						active: null,
						billingKey: '',
						cardName: '',
						cardNumber: '',
						coupons: null,
					};
				},
				set: function (model) {
					return {
						createAt: toSafeValue(model.createAt),
						plan: toSafeValue(model.plan),
						hospitalWorkerId: toText(model.hospitalWorkerId),
						hospitalId: toText(model.hospitalId),
						paymentDurationMonth: toNumber(model.paymentDurationMonth),
						payerName: toText(model.payerName),
						payerPhone: toText(model.payerPhone),
						active: toSafeValue(model.active),
						billingKey: toText(model.billingKey),
						cardName: toText(model.cardName),
						cardNumber: toText(model.cardNumber),
						coupons: toSafeValue(model.coupons),
					};
				},
			},

			HospitalPaymentModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						subscription: null,
						scheduledAt: null,
						paid: null,
						payAt: null,
						error: null,
						errorMessage: '',
						errorCode: '',
						hospitalId: '',
						hospitalWorkerId: '',
						subscriptionId: '',
						cancel: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						subscription: toSafeValue(model.subscription),
						scheduledAt: toSafeValue(model.scheduledAt),
						paid: toSafeValue(model.paid),
						payAt: toSafeValue(model.payAt),
						error: toSafeValue(model.error),
						errorMessage: toText(model.errorMessage),
						errorCode: toText(model.errorCode),
						hospitalId: toText(model.hospitalId),
						hospitalWorkerId: toText(model.hospitalWorkerId),
						subscriptionId: toText(model.subscriptionId),
						cancel: toSafeValue(model.cancel),
					};
				},
			},

			PaymentModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						billingKey: '',
						name: '',
						amount: '',
						payerName: '',
						payerPhone: '',
						paid: null,
						payAt: null,
						error: null,
						errorMessage: '',
						errorCode: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						billingKey: toText(model.billingKey),
						name: toText(model.name),
						amount: toText(model.amount),
						payerName: toText(model.payerName),
						payerPhone: toText(model.payerPhone),
						paid: toSafeValue(model.paid),
						payAt: toSafeValue(model.payAt),
						error: toSafeValue(model.error),
						errorMessage: toText(model.errorMessage),
						errorCode: toText(model.errorCode),
					};
				},
			},

			MembershipUsageModel: {
				create: function () {
					return {
						id: '',
						hospitalId: '',
						month: null,
						normalClinicCount: 0,
						audioClinicCount: 0,
						videoClinicCount: 0,
						normalClinicSeconds: 0,
						audioClinicSeconds: 0,
						videoClinicSeconds: 0,
						pushCount: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						hospitalId: toText(model.hospitalId),
						month: toSafeValue(model.month),
						normalClinicCount: toNumber(model.normalClinicCount),
						audioClinicCount: toNumber(model.audioClinicCount),
						videoClinicCount: toNumber(model.videoClinicCount),
						normalClinicSeconds: toNumber(model.normalClinicSeconds),
						audioClinicSeconds: toNumber(model.audioClinicSeconds),
						videoClinicSeconds: toNumber(model.videoClinicSeconds),
						pushCount: toNumber(model.pushCount),
					};
				},
			},

			HospitalWorkUpdateLogModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						doctorMemo: '',
						nextScheduleAfter: 0,
						nextScheduleDurationUnit: '',
						nextClinicDateTime: null,
						nextScheduleDescription: '',
						hospitalWorkerId: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						doctorMemo: toText(model.doctorMemo),
						nextScheduleAfter: toNumber(model.nextScheduleAfter),
						nextScheduleDurationUnit: toText(model.nextScheduleDurationUnit),
						nextClinicDateTime: toSafeValue(model.nextClinicDateTime),
						nextScheduleDescription: toText(model.nextScheduleDescription),
						hospitalWorkerId: toText(model.hospitalWorkerId),
					};
				},
			},

			HospitalWorkModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						reservationDateTime: null,
						callStartTime: null,
						callEndTime: null,
						clinicState: null,
						patientId: '',
						patientName: '',
						patientAddress: '',
						patientPhone: '',
						patientResidentNumber: '',
						patientGender: '',
						patientBirth: null,
						medicationInUse: '',
						allergy: '',
						diseases: null,
						isSecondTimeClinic: null,
						isVideoClinic: null,
						symptomDescription: '',
						images: null,
						requestCertificates: null,
						hospitalId: '',
						hospitalName: '',
						doctorId: '',
						doctorName: '',
						patientUserMemo: '',
						waitingTimeMinutes: 0,
						doctorMemo: '',
						priceBenefit: 0,
						priceNonBenefit: 0,
						priceDelivery: 0,
						prescriptionFileUrl: '',
						prescriptionBriefDescription: '',
						isNextScheduleRequired: null,
						nextScheduleAfter: 0,
						nextScheduleDurationUnit: '',
						nextClinicDate: 0,
						nextScheduleDescription: '',
						videoRecordFileUrl: '',
						audioRecordFileUrl: '',
						payAt: null,
						pharmacyId: '',
						pharmacyWorkId: '',
						pharmacyName: '',
						isSettled: null,
						settledAt: null,
						settleAmount: 0,
						paymentAmount: 0,
						paymentResult: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						reservationDateTime: toSafeValue(model.reservationDateTime),
						callStartTime: toSafeValue(model.callStartTime),
						callEndTime: toSafeValue(model.callEndTime),
						clinicState: toSafeValue(model.clinicState),
						patientId: toText(model.patientId),
						patientName: toText(model.patientName),
						patientAddress: toText(model.patientAddress),
						patientPhone: toText(model.patientPhone),
						patientResidentNumber: toText(model.patientResidentNumber),
						patientGender: toText(model.patientGender),
						patientBirth: toSafeValue(model.patientBirth),
						medicationInUse: toText(model.medicationInUse),
						allergy: toText(model.allergy),
						diseases: toSafeValue(model.diseases),
						isSecondTimeClinic: toSafeValue(model.isSecondTimeClinic),
						isVideoClinic: toSafeValue(model.isVideoClinic),
						symptomDescription: toText(model.symptomDescription),
						images: toSafeValue(model.images),
						requestCertificates: toSafeValue(model.requestCertificates),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						doctorId: toText(model.doctorId),
						doctorName: toText(model.doctorName),
						patientUserMemo: toText(model.patientUserMemo),
						waitingTimeMinutes: toNumber(model.waitingTimeMinutes),
						doctorMemo: toText(model.doctorMemo),
						priceBenefit: toNumber(model.priceBenefit),
						priceNonBenefit: toNumber(model.priceNonBenefit),
						priceDelivery: toNumber(model.priceDelivery),
						prescriptionFileUrl: toText(model.prescriptionFileUrl),
						prescriptionBriefDescription: toText(model.prescriptionBriefDescription),
						isNextScheduleRequired: toSafeValue(model.isNextScheduleRequired),
						nextScheduleAfter: toNumber(model.nextScheduleAfter),
						nextScheduleDurationUnit: toText(model.nextScheduleDurationUnit),
						nextClinicDate: toNumber(model.nextClinicDate),
						nextScheduleDescription: toText(model.nextScheduleDescription),
						videoRecordFileUrl: toText(model.videoRecordFileUrl),
						audioRecordFileUrl: toText(model.audioRecordFileUrl),
						payAt: toSafeValue(model.payAt),
						pharmacyId: toText(model.pharmacyId),
						pharmacyWorkId: toText(model.pharmacyWorkId),
						pharmacyName: toText(model.pharmacyName),
						isSettled: toSafeValue(model.isSettled),
						settledAt: toSafeValue(model.settledAt),
						settleAmount: toNumber(model.settleAmount),
						paymentAmount: toNumber(model.paymentAmount),
						paymentResult: toSafeValue(model.paymentResult),
					};
				},
			},

			ReservedPushNotificationModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						reservedAt: null,
						sended: null,
						sendedAt: null,
						title: '',
						content: '',
						link: '',
						senderType: '',
						senderId: '',
						senderName: '',
						target: null,
						receiverType: '',
						receiverIds: null,
						receivedAppUserIds: null,
						receivedHospitalUserIds: null,
						receivedPharmacyUserIds: null,
						sendMethods: null,
						spentMileages: 0,
						hospitalWorkId: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						reservedAt: toSafeValue(model.reservedAt),
						sended: toSafeValue(model.sended),
						sendedAt: toSafeValue(model.sendedAt),
						title: toText(model.title),
						content: toText(model.content),
						link: toText(model.link),
						senderType: toText(model.senderType),
						senderId: toText(model.senderId),
						senderName: toText(model.senderName),
						target: toSafeValue(model.target),
						receiverType: toText(model.receiverType),
						receiverIds: toSafeValue(model.receiverIds),
						receivedAppUserIds: toSafeValue(model.receivedAppUserIds),
						receivedHospitalUserIds: toSafeValue(model.receivedHospitalUserIds),
						receivedPharmacyUserIds: toSafeValue(model.receivedPharmacyUserIds),
						sendMethods: toSafeValue(model.sendMethods),
						spentMileages: toNumber(model.spentMileages),
						hospitalWorkId: toText(model.hospitalWorkId),
					};
				},
			},

			HospitalMileagePaymentModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						autoPurchase: null,
						amount: 0,
						paymentResult: null,
						mileage: 0,
						hospitalId: '',
						paid: null,
						errorMessage: '',
						payAt: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						autoPurchase: toSafeValue(model.autoPurchase),
						amount: toNumber(model.amount),
						paymentResult: toSafeValue(model.paymentResult),
						mileage: toNumber(model.mileage),
						hospitalId: toText(model.hospitalId),
						paid: toSafeValue(model.paid),
						errorMessage: toText(model.errorMessage),
						payAt: toSafeValue(model.payAt),
					};
				},
			},

			TempHolidayModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						hospitalId: '',
						pharmacyId: '',
						hospitalWorkerId: '',
						date: null,
						name: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						hospitalId: toText(model.hospitalId),
						pharmacyId: toText(model.pharmacyId),
						hospitalWorkerId: toText(model.hospitalWorkerId),
						date: toSafeValue(model.date),
						name: toText(model.name),
					};
				},
			},

			PharmacyModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						accountId: '',
						accountUserName: '',
						accountUserBirth: null,
						accountUserPhone: '',
						accountUserEmail: '',
						pharmacistLicenseImageUrl: '',
						numOfFavorites: 0,
						name: '',
						address: '',
						addressDetail: '',
						gpsX: 0,
						gpsY: 0,
						phone: '',
						fax: '',
						mondayEnable: null,
						tuesdayEnable: null,
						wednesdayEnable: null,
						thursdayEnable: null,
						fridayEnable: null,
						saturdayEnable: null,
						sundayEnable: null,
						mondayOpenAt: null,
						tuesdayOpenAt: null,
						wednesdayOpenAt: null,
						thursdayOpenAt: null,
						fridayOpenAt: null,
						saturdayOpenAt: null,
						sundayOpenAt: null,
						mondayCloseAt: null,
						tuesdayCloseAt: null,
						wednesdayCloseAt: null,
						thursdayCloseAt: null,
						fridayCloseAt: null,
						saturdayCloseAt: null,
						sundayCloseAt: null,
						availableAtHoliday: null,
						pharmacyImages: null,
						businessLicenseFileUrl: '',
						accountBankName: '',
						accountNumber: '',
						accountOwnerName: '',
						invoiceEmail: '',
						tagList: null,
						businessNumber: '',
						settlementPersonName: '',
						settlementPersonPhone: '',
						mondayLunchStartAt: null,
						tuesdayLunchStartAt: null,
						wednesdayLunchStartAt: null,
						thursdayLunchStartAt: null,
						fridayLunchStartAt: null,
						saturdayLunchStartAt: null,
						sundayLunchStartAt: null,
						mondayLunchEndAt: null,
						tuesdayLunchEndAt: null,
						wednesdayLunchEndAt: null,
						thursdayLunchEndAt: null,
						fridayLunchEndAt: null,
						saturdayLunchEndAt: null,
						sundayLunchEndAt: null,
						operatingTimes: null,
						mondayLunch: null,
						tuesdayLunch: null,
						wednesdayLunch: null,
						thursdayLunch: null,
						fridayLunch: null,
						saturdayLunch: null,
						sundayLunch: null,
						postCode: '',
						holidayWeekDays: null,
						phone1: '',
						phone2: '',
						phone3: '',
						fax1: '',
						fax2: '',
						fax3: '',
						settlementPersonPhone1: '',
						settlementPersonPhone2: '',
						settlementPersonPhone3: '',
						accountUserPhone1: '',
						accountUserPhone2: '',
						accountUserPhone3: '',
						quit: null,
						whyQuit: null,
						approveState: '',
						kakaoTalkIdNotification: null,
						kakaoTalkId: '',
						shopOpened: null,
						isAddressJibun: null,
						gender: '',
						pharmacyNumber: '',
						enableInDay: null,
						enableQuick: null,
						enableMail: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						accountId: toText(model.accountId),
						accountUserName: toText(model.accountUserName),
						accountUserBirth: toSafeValue(model.accountUserBirth),
						accountUserPhone: toText(model.accountUserPhone),
						accountUserEmail: toText(model.accountUserEmail),
						pharmacistLicenseImageUrl: toText(model.pharmacistLicenseImageUrl),
						numOfFavorites: toNumber(model.numOfFavorites),
						name: toText(model.name),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						gpsX: toNumber(model.gpsX),
						gpsY: toNumber(model.gpsY),
						phone: toText(model.phone),
						fax: toText(model.fax),
						mondayEnable: toSafeValue(model.mondayEnable),
						tuesdayEnable: toSafeValue(model.tuesdayEnable),
						wednesdayEnable: toSafeValue(model.wednesdayEnable),
						thursdayEnable: toSafeValue(model.thursdayEnable),
						fridayEnable: toSafeValue(model.fridayEnable),
						saturdayEnable: toSafeValue(model.saturdayEnable),
						sundayEnable: toSafeValue(model.sundayEnable),
						mondayOpenAt: toSafeValue(model.mondayOpenAt),
						tuesdayOpenAt: toSafeValue(model.tuesdayOpenAt),
						wednesdayOpenAt: toSafeValue(model.wednesdayOpenAt),
						thursdayOpenAt: toSafeValue(model.thursdayOpenAt),
						fridayOpenAt: toSafeValue(model.fridayOpenAt),
						saturdayOpenAt: toSafeValue(model.saturdayOpenAt),
						sundayOpenAt: toSafeValue(model.sundayOpenAt),
						mondayCloseAt: toSafeValue(model.mondayCloseAt),
						tuesdayCloseAt: toSafeValue(model.tuesdayCloseAt),
						wednesdayCloseAt: toSafeValue(model.wednesdayCloseAt),
						thursdayCloseAt: toSafeValue(model.thursdayCloseAt),
						fridayCloseAt: toSafeValue(model.fridayCloseAt),
						saturdayCloseAt: toSafeValue(model.saturdayCloseAt),
						sundayCloseAt: toSafeValue(model.sundayCloseAt),
						availableAtHoliday: toSafeValue(model.availableAtHoliday),
						pharmacyImages: toSafeValue(model.pharmacyImages),
						businessLicenseFileUrl: toText(model.businessLicenseFileUrl),
						accountBankName: toText(model.accountBankName),
						accountNumber: toText(model.accountNumber),
						accountOwnerName: toText(model.accountOwnerName),
						invoiceEmail: toText(model.invoiceEmail),
						tagList: toSafeValue(model.tagList),
						businessNumber: toText(model.businessNumber),
						settlementPersonName: toText(model.settlementPersonName),
						settlementPersonPhone: toText(model.settlementPersonPhone),
						mondayLunchStartAt: toSafeValue(model.mondayLunchStartAt),
						tuesdayLunchStartAt: toSafeValue(model.tuesdayLunchStartAt),
						wednesdayLunchStartAt: toSafeValue(model.wednesdayLunchStartAt),
						thursdayLunchStartAt: toSafeValue(model.thursdayLunchStartAt),
						fridayLunchStartAt: toSafeValue(model.fridayLunchStartAt),
						saturdayLunchStartAt: toSafeValue(model.saturdayLunchStartAt),
						sundayLunchStartAt: toSafeValue(model.sundayLunchStartAt),
						mondayLunchEndAt: toSafeValue(model.mondayLunchEndAt),
						tuesdayLunchEndAt: toSafeValue(model.tuesdayLunchEndAt),
						wednesdayLunchEndAt: toSafeValue(model.wednesdayLunchEndAt),
						thursdayLunchEndAt: toSafeValue(model.thursdayLunchEndAt),
						fridayLunchEndAt: toSafeValue(model.fridayLunchEndAt),
						saturdayLunchEndAt: toSafeValue(model.saturdayLunchEndAt),
						sundayLunchEndAt: toSafeValue(model.sundayLunchEndAt),
						operatingTimes: toSafeValue(model.operatingTimes),
						mondayLunch: toSafeValue(model.mondayLunch),
						tuesdayLunch: toSafeValue(model.tuesdayLunch),
						wednesdayLunch: toSafeValue(model.wednesdayLunch),
						thursdayLunch: toSafeValue(model.thursdayLunch),
						fridayLunch: toSafeValue(model.fridayLunch),
						saturdayLunch: toSafeValue(model.saturdayLunch),
						sundayLunch: toSafeValue(model.sundayLunch),
						postCode: toText(model.postCode),
						holidayWeekDays: toSafeValue(model.holidayWeekDays),
						phone1: toText(model.phone1),
						phone2: toText(model.phone2),
						phone3: toText(model.phone3),
						fax1: toText(model.fax1),
						fax2: toText(model.fax2),
						fax3: toText(model.fax3),
						settlementPersonPhone1: toText(model.settlementPersonPhone1),
						settlementPersonPhone2: toText(model.settlementPersonPhone2),
						settlementPersonPhone3: toText(model.settlementPersonPhone3),
						accountUserPhone1: toText(model.accountUserPhone1),
						accountUserPhone2: toText(model.accountUserPhone2),
						accountUserPhone3: toText(model.accountUserPhone3),
						quit: toSafeValue(model.quit),
						whyQuit: toSafeValue(model.whyQuit),
						approveState: toText(model.approveState),
						kakaoTalkIdNotification: toSafeValue(model.kakaoTalkIdNotification),
						kakaoTalkId: toText(model.kakaoTalkId),
						shopOpened: toSafeValue(model.shopOpened),
						isAddressJibun: toSafeValue(model.isAddressJibun),
						gender: toText(model.gender),
						pharmacyNumber: toText(model.pharmacyNumber),
						enableInDay: toSafeValue(model.enableInDay),
						enableQuick: toSafeValue(model.enableQuick),
						enableMail: toSafeValue(model.enableMail),
					};
				},
			},

			RelatedPharmacyModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						pharmacyId: '',
						name: '',
						phone: '',
						address: '',
						addressDetail: '',
						order: 0,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						pharmacyId: toText(model.pharmacyId),
						name: toText(model.name),
						phone: toText(model.phone),
						address: toText(model.address),
						addressDetail: toText(model.addressDetail),
						order: toNumber(model.order),
					};
				},
			},

			DocumentModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						title: '',
						fileUrls: null,
						hospitalWorkId: '',
						hospitalName: '',
						documentType: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						title: toText(model.title),
						fileUrls: toSafeValue(model.fileUrls),
						hospitalWorkId: toText(model.hospitalWorkId),
						hospitalName: toText(model.hospitalName),
						documentType: toSafeValue(model.documentType),
					};
				},
			},

			PartnerPointUsageModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						usageType: '',
						partnerId: '',
						partnerName: '',
						hospitalId: '',
						hospitalName: '',
						description: '',
						months: null,
						amount: 0,
						memo: '',
						transferDate: null,
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						usageType: toText(model.usageType),
						partnerId: toText(model.partnerId),
						partnerName: toText(model.partnerName),
						hospitalId: toText(model.hospitalId),
						hospitalName: toText(model.hospitalName),
						description: toText(model.description),
						months: toSafeValue(model.months),
						amount: toNumber(model.amount),
						memo: toText(model.memo),
						transferDate: toSafeValue(model.transferDate),
					};
				},
			},

			PartnerPointTransferModel: {
				create: function () {
					return {
						id: '',
						createAt: null,
						partnerId: '',
						partnerName: '',
						amount: 0,
						transferAmount: 0,
						bankName: '',
						accountNumber: '',
						accountOwner: '',
						state: '',
						transferDate: null,
						tax: null,
						taxResidentNumber: '',
						taxCompany: '',
						taxBusinessNumber: '',
						taxOwner: '',
						taxUptae: '',
						taxJongmok: '',
						taxAddress: '',
						taxEmail: '',
						taxManager: '',
						taxPhone: '',
					};
				},
				set: function (model) {
					return {
						id: toText(model.id),
						createAt: toSafeValue(model.createAt),
						partnerId: toText(model.partnerId),
						partnerName: toText(model.partnerName),
						amount: toNumber(model.amount),
						transferAmount: toNumber(model.transferAmount),
						bankName: toText(model.bankName),
						accountNumber: toText(model.accountNumber),
						accountOwner: toText(model.accountOwner),
						state: toText(model.state),
						transferDate: toSafeValue(model.transferDate),
						tax: toSafeValue(model.tax),
						taxResidentNumber: toText(model.taxResidentNumber),
						taxCompany: toText(model.taxCompany),
						taxBusinessNumber: toText(model.taxBusinessNumber),
						taxOwner: toText(model.taxOwner),
						taxUptae: toText(model.taxUptae),
						taxJongmok: toText(model.taxJongmok),
						taxAddress: toText(model.taxAddress),
						taxEmail: toText(model.taxEmail),
						taxManager: toText(model.taxManager),
						taxPhone: toText(model.taxPhone),
					};
				},
			},
		};
	}
};

Vue.use(models);
