import Vue from "vue";
import firebase from 'firebase/compat/app'

export default {
};

export const auth = {
    install(Vue) {
        Vue.prototype.$auth = {
            login: async function (email, password) {
                await firebase
                    .auth()
                    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                    .then(async () => {
                        await firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then(() => {
                            })
                            .catch((error) => {
                                throw (error);
                            });
                    })
                    .catch((error) => {
                        throw (error);
                    });
            },
            logout: async function () {
                firebase.auth().signOut();
            },
            currentUser: function () {
                return firebase.auth().currentUser;
            },
            reauthenticate: async function (password) {
                let user = firebase.auth().currentUser;
                let credential =
                    firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        password
                    );
                await user.reauthenticateWithCredential(credential);
            },
            updatePassword: async function (newPassword) {
                let user = firebase.auth().currentUser;
                await user
                    .updatePassword(newPassword)
                    .catch((e) => {
                        throw (e);
                    });
            },
            createRecaptchaVerifier(id, callback) {
                firebase.auth().languageCode = 'ko';
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(id, {
                    'size': 'normal',
                    'callback': (response) => {
                        callback(response);
                    },
                    'expired-callback': () => {
                        callback();
                    }
                });
            },
            sendPhoneAuthCode: async function (phone) {
                const appVerifier = window.recaptchaVerifier;
                var result = null;
                await firebase.auth().signInWithPhoneNumber(phone, appVerifier)
                    .then((confirmationResult) => {
                        // SMS sent. Prompt user to type the code from the message, then sign the
                        // user in with confirmationResult.confirm(code).
                        //window.confirmationResult = confirmationResult;
                        // ...
                        result = confirmationResult;
                    }).catch((e) => {
                        // Error; SMS not sent
                        // ...
                        throw (e);
                    });
                return result;
            },
        }
    }
};

Vue.use(auth);