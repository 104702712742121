// Hospital:hospitals
// HospitalWorker:hospitalWorkers
// HospitalWork:hospitalWorks

import Vue from "vue";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {};

export const database = {
  install(Vue) {
    Vue.prototype.$database = {
      currentTimestamp: function() {
        return firebase.firestore.Timestamp.now();
      },
      dateToTimestamp: function(date) {
        return firebase.firestore.Timestamp.fromDate(new Date(date));
      },
      timestampToDate: function(timestamp) {
        return timestamp.toDate();
      },
      onHospitals: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc, signUpCode) {
        let ref = firebase.firestore()
          .collection("hospitals")
          .where("signUpCode", "==", signUpCode)
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadHospitals: async function (signUpCodePhone) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitals")
          .where("signUpCodePhone", "==", signUpCodePhone)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalsOfCode: async function(code) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitals")
          .where("signUpCode", "==", code)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospital: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitals")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospital: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitals")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospital: async function(id, model) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(id)
          .update(model);
      },
      deleteHospital: async function(id) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(id)
          .delete();
      },
      setHospital: async function(id, model) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(id)
          .set(model);
      },

      onHospitalWorkers: function(hospitalId, startDate, endDate, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalWorkers")
          .where("hospitalId", "==", hospitalId)
          .where("createAt", ">=", startDate)
          .where("createAt", "<=", endDate)
          .orderBy("createAt", "desc")
          .onSnapshot(onSnapshot);
      },
      onHospitalWorker: function(hospitalId, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalWorkers")
          .doc(hospitalId)
          .onSnapshot(onSnapshot);
      },
      loadHospitalWorkers: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorkers")
          .orderBy("createAt", "asc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWorkersByType: async function(hospitalId, type) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorkers")
          .where("hospitalId", "==", hospitalId)
          .where("hospitalWorkerType", "==", type)
          .orderBy("createAt", "asc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWorkersByHospitalId: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorkers")
          .where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "asc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWorker: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalWorkers")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      randomValue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      createHospitalCode() {
        return this.randomValue(10000, 99999) + "";
      },
      addHospital2: async function(hospitalWorkerId, hospital) {
        var hospitalRef = firebase.firestore()
          .collection("hospitals")
          .doc();
        var hospitalWorkerRef = firebase.firestore()
          .collection("hospitalWorkers")
          .doc(hospitalWorkerId);
        await firebase.firestore().runTransaction(async (transaction) => {
          await transaction.get(hospitalWorkerRef).then(() => {
            hospital.id = hospitalRef.id;
            transaction.set(hospitalRef, hospital);
            transaction.update(hospitalWorkerRef, {
              hospitalId: hospitalRef.id,
              hospitalName: hospital.name
            });
          });
        });
        return hospitalRef.id;
      },
      addOnlyHospitalWorker: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalWorkers")
          .doc(model.id);
        let addModel = model;
        await newDocRef.set(addModel);
      },
      findHospitalCode: async function(hospitalCode) {
        let result = false;
        await firebase.firestore()
          .collection("hospitals")
          .where("hospitalCode", "==", hospitalCode)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              result = true;
            }
          });
        return result;
      },
      editHospitalWorker: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalWorkers")
          .doc(id)
          .update(model);
      },
      deleteHospitalWorker: async function(id) {
        await firebase.firestore()
          .collection("hospitalWorkers")
          .doc(id)
          .delete();
      },
      setHospitalWorker: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalWorkers")
          .doc(id)
          .set(model);
      },
      onHospitalWorksByDate: function(hospitalId, fieldName, order, startDate, endDate, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where(fieldName, ">=", startDate)
          .where(fieldName, "<=", endDate)
          .orderBy(fieldName, order)
          .onSnapshot(onSnapshot);
      },
      loadHospitalWorksByPatientId: async function(hospitalId, patientId, hospitalWorkId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("patientId", "==", patientId)
          .where("clinicState", "==", "ClinicState.done")
          .orderBy("clinicEndAt", "desc")
          .limit(3)
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              if (itemInfo.id != hospitalWorkId) {
                itemList.push(itemInfo);
              }
            }
          });
        return itemList;
      },
      loadHospitalWorksByPatientIdAndResidentNumber: async function(hospitalId, patientId, hospitalWorkId, patientResidentNumber) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("patientId", "==", patientId)
          .where("patientResidentNumber", "==", patientResidentNumber)
          .where("clinicState", "==", "ClinicState.done")
          .orderBy("clinicEndAt", "desc")
          .limit(3)
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              if ((hospitalWorkId == null) || (itemInfo.id != hospitalWorkId)) {
                itemList.push(itemInfo);
              }
            }
          });
        return itemList;
      },
      onHospitalWorks: function(
        hospitalId,
        startDate,
        endDate,
        patientNameFilter,
        clinicStateFilter,
        isVideoClinicFilter,
        timestampField,
        orderBy,
        onSnapshot) {
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("clinicState", "in", clinicStateFilter);
        if (startDate != null) {
          ref = ref.where(timestampField, ">=", startDate);
        }
        if (endDate != null) {
          ref = ref.where(timestampField, "<=", endDate);
        }
        if (patientNameFilter.length > 0) {
          ref = ref.where("patientName", "==", patientNameFilter);
        }
        if (isVideoClinicFilter != null) {
          ref = ref.where("isVideoClinic", "==", isVideoClinicFilter);
        }
        return ref.orderBy(timestampField, orderBy)
          .onSnapshot(onSnapshot);
      },
      loadHospitalWorks: async function(
        hospitalId,
        startDate,
        endDate,
        patientNameFilter,
        clinicStateFilter,
        isVideoClinicFilter) {
        let itemList = [];
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("clinicState", "in", clinicStateFilter);
        if ((startDate != null) && (endDate != null)) {
          ref = ref.where("reservationDateTime", ">=", startDate)
            .where("reservationDateTime", "<=", endDate);
        }
        if (patientNameFilter.length > 0) {
          ref = ref.where("patientName", "==", patientNameFilter);
        }
        if (isVideoClinicFilter != null) {
          ref = ref.where("isVideoClinic", "==", isVideoClinicFilter);
        }
        await ref.orderBy("reservationDateTime", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWorks2: async function(
        hospitalId,
        purchaseStartDate,
        purchaseEndDate,
        settledStartDate,
        settledEndDate,
        patientNameFilter,
        isSettledFilter,
        autoPayFilter,
        orderBy) {
        let itemList = [];
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId);
        if ((purchaseStartDate != null) && (purchaseEndDate != null)) {
          ref = ref.where("payAt", ">=", purchaseStartDate)
            .where("payAt", "<=", purchaseEndDate);
        }
        if ((settledStartDate != null) && (settledEndDate != null)) {
          ref = ref.where("settledAt", ">=", settledStartDate)
            .where("settledAt", "<=", settledEndDate);
        }
        if (patientNameFilter.length > 0) {
          ref = ref.where("patientName", "==", patientNameFilter);
        }
        if (autoPayFilter != null) {
          ref = ref.where("isAutoPay", "==", autoPayFilter);
        }
        await ref.orderBy(orderBy, "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;

              if (isSettledFilter != null) {
                if (isSettledFilter == true) {
                  if (itemInfo.isSettled == true) {
                    itemList.push(itemInfo);
                  }
                } else {
                  if (itemInfo.isSettled != true) {
                    itemList.push(itemInfo);
                  }
                }
              } else {
                itemList.push(itemInfo);
              }
            }
          });
        return itemList;
      },
      loadHospitalWorksByPatientId2: async function(
        hospitalId,
        patientId,
        clinicStates) {
        let itemList = [];
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("patientId", "==", patientId)
          .where("clinicState", "in", clinicStates);
        await ref.orderBy("reservationDateTime", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWork: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalWork: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalWorks")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
        return addModel.id;
      },
      editHospitalWork: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(id)
          .update(model);
      },
      acceptReservation: async function(id) {
        let result = false;
        await firebase.firestore().runTransaction(async (transaction) => {
          var ref = firebase.firestore()
            .collection("hospitalWorks")
            .doc(id);
          await transaction.get(ref).then((documentSnapshot) => {
            if (documentSnapshot.exists) {
              let hospitalWork = documentSnapshot.data();
              if (hospitalWork.clinicState == "ClinicState.waiting") {
                transaction.update(ref, {
                  clinicState: "ClinicState.accepted"
                });
                result = true;
              }
            }
          });
        });
        return result;
      },
      startReservation: async function(id, model) {
        let result = false;
        await firebase.firestore().runTransaction(async (transaction) => {
          var ref = firebase.firestore()
            .collection("hospitalWorks")
            .doc(id);
          await transaction.get(ref).then((documentSnapshot) => {
            if (documentSnapshot.exists) {
              let hospitalWork = documentSnapshot.data();
              if (hospitalWork.clinicState == "ClinicState.accepted") {
                transaction.update(ref, model);
                result = true;
              }
            }
          });
        });
        return result;
      },
      deleteHospitalWork: async function(id) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(id)
          .delete();
      },
      setHospitalWork: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(id)
          .set(model);
      },
      loadNewHospitalWorks: async function(
        hospitalId, startDate, endDate, clinicState, lastDoc, updatedLastDoc) {
        let itemList = [];
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("createAt", ">=", startDate)
          .where("createAt", "<=", endDate)
          .where("hospitalId", "==", hospitalId)
          .where("clinicState", "==", clinicState);
        if (lastDoc != null) {
          ref = ref.startAfter(lastDoc);
        }
        ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
              if (updatedLastDoc != null) {
                updatedLastDoc(querySnapshot.docs[i]);
              }
            }
          });
        return itemList;
      },
      onQnas: function(onSnapshot) {
        return firebase.firestore()
          .collection("qnas")
          .onSnapshot(onSnapshot);
      },
      loadQnas: async function(id) {
        let itemList = [];
        await firebase.firestore()
          .collection("qnas")
          .where("questionerType", "==", "QuestionerType.hospital")
          .where("hospitalWorkerId", "==", id)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadQna: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("qnas")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addQna: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("qnas")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editQna: async function(id, model) {
        await firebase.firestore()
          .collection("qnas")
          .doc(id)
          .update(model);
      },
      deleteQna: async function(id) {
        await firebase.firestore()
          .collection("qnas")
          .doc(id)
          .delete();
      },
      setQna: async function(id, model) {
        await firebase.firestore()
          .collection("qnas")
          .doc(id)
          .set(model);
      },

      onAdminNotices: function(onSnapshot) {
        return firebase.firestore()
          .collection("adminNotices")
          .where("adminNoticeTarget", "==", "AdminNoticeTarget.hospital")
          .orderBy("createAt", "desc")
          .onSnapshot(onSnapshot);
      },
      loadAdminNotices: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("adminNotices")
          .where("adminNoticeTarget", "==", "AdminNoticeTarget.hospital")
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadAdminNotice: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("adminNotices")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addAdminNotice: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("adminNotices")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editAdminNotice: async function(id, model) {
        await firebase.firestore()
          .collection("adminNotices")
          .doc(id)
          .update(model);
      },
      deleteAdminNotice: async function(id) {
        await firebase.firestore()
          .collection("adminNotices")
          .doc(id)
          .delete();
      },
      setAdminNotice: async function(id, model) {
        await firebase.firestore()
          .collection("adminNotices")
          .doc(id)
          .set(model);
      },
      onHospitalNotices: function(onSnapshot) {
        return firebase.firestore()
          .collection("hospitalNotices")
          .onSnapshot(onSnapshot);
      },
      loadHospitalNotices: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalNotices")
          .where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalNotice: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalNotices")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalNotice: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalNotices")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospitalNotice: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalNotices")
          .doc(id)
          .update(model);
      },
      deleteHospitalNotice: async function(id) {
        await firebase.firestore()
          .collection("hospitalNotices")
          .doc(id)
          .delete();
      },
      setHospitalNotice: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalNotices")
          .doc(id)
          .set(model);
      },
      onNonBenefits: function(onSnapshot) {
        return firebase.firestore()
          .collection("nonBenefits")
          .onSnapshot(onSnapshot);
      },
      loadNonBenefits: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("nonBenefits")
          .where("hospitalId", "==", hospitalId)
          .orderBy("orderPriority", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadNonBenefit: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("nonBenefits")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addNonBenefit: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("nonBenefits")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editNonBenefit: async function(id, model) {
        await firebase.firestore()
          .collection("nonBenefits")
          .doc(id)
          .update(model);
      },
      deleteNonBenefit: async function(id) {
        await firebase.firestore()
          .collection("nonBenefits")
          .doc(id)
          .delete();
      },
      setNonBenefit: async function(id, model) {
        await firebase.firestore()
          .collection("nonBenefits")
          .doc(id)
          .set(model);
      },
      onUsers: function(onSnapshot) {
        return firebase.firestore()
          .collection("users")
          .onSnapshot(onSnapshot);
      },
      loadUsers: async function(hospitalId, clinicStartDate, clinicEndDate, reservationStartDate, reservationEndDate) {
        let users = {};
        let ref = firebase.firestore()
          .collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId);

        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let hospitalWork = querySnapshot.docs[i].data();

              // a. 진료일 필터가 설정된 경우 진료완료 상태이면서 진료일 범위에 포함되어있으면 사용자 목록에 표시돼야 함
              // b. 예약일 필터가 설정된 경우 진료예약 상태이면서 예약일 범위에 포함되어있으면 사용자 목록에 표시돼야 함
              // a만 설정되어있으면 a 조건이 참이어야 함
              // b만 설정되어있으면 b 조건이 참이어야 함
              // 둘다 설정되어있으면 a, b 조건 중 하나라도 참이면 사용자 목록에 표시돼야 함

              let inClinicDate = false;
              if ((clinicStartDate != null) && (clinicEndDate != null)) { // 진료일 필터가 설정된 경우
                if (hospitalWork.clinicState == "ClinicState.done") { // 진료완료 상태인 경우
                  if ((hospitalWork.clinicEndAt != undefined) && (hospitalWork.clinicEndAt != null)) {
                    if ((hospitalWork.clinicEndAt.toDate() >= clinicStartDate) && (hospitalWork.clinicEndAt.toDate() <= clinicEndDate)) {
                      inClinicDate = true;
                    }
                  }
                }
              }

              let inReservationDate = false;
              if ((reservationStartDate != null) && (reservationEndDate != null)) { // 예약일 필터가 설정된 경우
                if ((hospitalWork.clinicState == "ClinicState.waiting") || (hospitalWork.clinicState == "ClinicState.accepted")) { // 진료 전 상태인 경우
                  if ((hospitalWork.reservationDateTime != undefined) && (hospitalWork.reservationDateTime != null)) {
                    if ((hospitalWork.reservationDateTime.toDate() >= reservationStartDate) && (hospitalWork.reservationDateTime.toDate() <= reservationEndDate)) {
                      inReservationDate = true;
                    }
                  }
                }
              }

              let addUser = false;
              if ((clinicStartDate != null) && (clinicEndDate != null) && (reservationStartDate != null) && (reservationEndDate != null)) {
                // 진료일 필터, 예약일 필터 모두 설정된 경우
                if (inClinicDate || inReservationDate) {
                  addUser = true;
                }
              } else if ((clinicStartDate != null) && (clinicEndDate != null)) {
                // 진료일 필터만 설정된 경우
                if (inClinicDate) {
                  addUser = true;
                }
              } else if ((reservationStartDate != null) && (reservationEndDate != null)) {
                // 예약일 필터만 설정된 경우
                if (inReservationDate) {
                  addUser = true;
                }
              } else {
                addUser = true;
              }

              if (addUser) {
                if ((users[hospitalWork.patientId] == null) || (users[hospitalWork.patientId] == undefined)) {
                  let userDoc = await firebase.firestore()
                    .collection("users")
                    .doc(hospitalWork.patientId)
                    .get();
                  if (userDoc.exists) {
                    let user = userDoc.data();
                    users[hospitalWork.patientId] = Object.assign(user, {
                      user: user,
                      clinicCount: 0,
                      reservationCount: 0,
                      clinicDate: null,
                      reservationDate: null,
                      paymentAmount: 0,
                      receivableAmount: 0
                    });
                  }
                }

                let user = users[hospitalWork.patientId];
                if ((user != undefined) && (user != null)) {
                  if (hospitalWork.clinicState == "ClinicState.done") {
                    user.clinicCount += 1;
                    if ((hospitalWork.clinicEndAt != undefined) && (hospitalWork.clinicEndAt != null)) {
                      if ((user.clinicDate == null) || (user.clinicDate.toDate() < hospitalWork.clinicEndAt.toDate())) {
                        user.clinicDate = hospitalWork.clinicEndAt;
                      }
                    }
                    if ((hospitalWork.paymentAmount != undefined) && (hospitalWork.paymentAmount != null)) {
                      if (hospitalWork.refunded == true) {
                        // 환불된 경우
                        // 미수금
                        user.receivableAmount += hospitalWork.paymentAmount;
                      } else if ((hospitalWork.payAt != undefined) && (hospitalWork.payAt != null)) {
                        // 결제 이력이 있는 경우
                        if (hospitalWork.payFail != true) { // 결제 실패가 아니면
                          user.paymentAmount += hospitalWork.paymentAmount;
                        } else { // 결제 실패
                          // 미수금
                          user.receivableAmount += hospitalWork.paymentAmount;
                        }
                      } else {
                        // 결제 이력이 없는 경우
                        // 미수금
                        user.receivableAmount += hospitalWork.paymentAmount;
                      }
                    }
                  } else if ((hospitalWork.clinicState == "ClinicState.waiting") || (hospitalWork.clinicState == "ClinicState.accepted")) {
                    user.reservationCount += 1;
                    if ((user.reservationDate == null)
                      || (user.reservationDate.toDate() < hospitalWork.reservationDateTime.toDate())) {
                      user.reservationDate = hospitalWork.reservationDateTime;
                    }
                  }

                  users[hospitalWork.patientId] = user;
                }
              }
            }
          });
        return users;
      },

      loadUsersOfFavoriteHospital: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("users")
          .where("favoriteHospitals", "array-contains", hospitalId)
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },

      loadAllUsers: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("users")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },

      loadUser: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("users")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addUser: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("users")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editUser: async function(id, model) {
        await firebase.firestore()
          .collection("users")
          .doc(id)
          .update(model);
      },
      deleteUser: async function(id) {
        await firebase.firestore()
          .collection("users")
          .doc(id)
          .delete();
      },
      setUser: async function(id, model) {
        await firebase.firestore()
          .collection("users")
          .doc(id)
          .set(model);
      },
      onHospitalCodes: function(onSnapshot) {
        return firebase.firestore()
          .collection("hospitalCodes")
          .onSnapshot(onSnapshot);
      },
      loadHospitalCodes: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalCodes")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalCode: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalCodes")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalCode: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalCodes")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospitalCode: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalCodes")
          .doc(id)
          .update(model);
      },
      deleteHospitalCode: async function(id) {
        await firebase.firestore()
          .collection("hospitalCodes")
          .doc(id)
          .delete();
      },
      setHospitalCode: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalCodes")
          .doc(id)
          .set(model);
      },
      onAdminConfigs: function(onSnapshot) {
        return firebase.firestore()
          .collection("adminConfigs")
          .onSnapshot(onSnapshot);
      },
      loadAdminConfigs: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("adminConfigs")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadAdminConfig: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("adminConfigs")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addAdminConfig: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("adminConfigs")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editAdminConfig: async function(id, model) {
        await firebase.firestore()
          .collection("adminConfigs")
          .doc(id)
          .update(model);
      },
      deleteAdminConfig: async function(id) {
        await firebase.firestore()
          .collection("adminConfigs")
          .doc(id)
          .delete();
      },
      setAdminConfig: async function(id, model) {
        await firebase.firestore()
          .collection("adminConfigs")
          .doc(id)
          .set(model);
      },
      onNotifications: function(onSnapshot) {
        return firebase.firestore()
          .collection("notifications")
          .onSnapshot(onSnapshot);
      },
      loadNotifications: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("notifications")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadNotification: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("notifications")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addNotification: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("notifications")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editNotification: async function(id, model) {
        await firebase.firestore()
          .collection("notifications")
          .doc(id)
          .update(model);
      },
      deleteNotification: async function(id) {
        await firebase.firestore()
          .collection("notifications")
          .doc(id)
          .delete();
      },
      setNotification: async function(id, model) {
        await firebase.firestore()
          .collection("notifications")
          .doc(id)
          .set(model);
      },
      onHospitalSubscriptions: function(onSnapshot) {
        return firebase.firestore()
          .collection("hospitalSubscriptions")
          .onSnapshot(onSnapshot);
      },
      loadHospitalSubscriptions: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalSubscriptionsByHospitalId: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadActiveHospitalSubscriptionsByHospitalId: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .where("hospitalId", "==", hospitalId)
          .where("active", "==", true)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalSubscription: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalSubscription: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
        return addModel.id;
      },
      editHospitalSubscription: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc(id)
          .update(model);
      },
      deleteHospitalSubscription: async function(id) {
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc(id)
          .delete();
      },
      setHospitalSubscription: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc(id)
          .set(model);
      },
      loadHospitalPaymentsByHospitalId: async function(type, hospitalId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("hospitalPayments");
        if (type != null) {
          ref = ref.where("type", "==", type);
        }
        await ref.where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalPaymentsBySubscriptionId: async function(subscriptionId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalPayments")
          .where("subscriptionId", "==", subscriptionId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalPayment: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalPayments")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalPayment: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalPayments")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
        return addModel.id;
      },
      editHospitalPayment: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalPayments")
          .doc(id)
          .update(model);
      },
      deleteHospitalPayment: async function(id) {
        await firebase.firestore()
          .collection("hospitalPayments")
          .doc(id)
          .delete();
      },
      setHospitalPayment: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalPayments")
          .doc(id)
          .set(model);
      },
      onPayments: function(onSnapshot) {
        return firebase.firestore()
          .collection("payments")
          .onSnapshot(onSnapshot);
      },
      loadPayments: async function(hospitalId, hospitalWorkId) {
        let itemList = [];
        await firebase.firestore()
          .collection("payments")
          .where("hospitalId", "==", hospitalId)
          .where("hospitalWorkId", "==", hospitalWorkId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPaymentsByRequestCertificate: async function(requestCertificateId) {
        let itemList = [];
        await firebase.firestore()
          .collection("payments")
          .where("certificateRequestId", "==", requestCertificateId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPayment: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("payments")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPayment: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("payments")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
        return addModel.id;
      },
      editPayment: async function(id, model) {
        await firebase.firestore()
          .collection("payments")
          .doc(id)
          .update(model);
      },
      deletePayment: async function(id) {
        await firebase.firestore()
          .collection("payments")
          .doc(id)
          .delete();
      },
      setPayment: async function(id, model) {
        await firebase.firestore()
          .collection("payments")
          .doc(id)
          .set(model);
      },
      onNotificationsFromStartDate: function(hospitalWorkerId, startDate, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalWorkers")
          .doc(hospitalWorkerId)
          .collection("notifications")
          .where("createAt", ">=", startDate)
          .onSnapshot(onSnapshot);
      },
      onRequestCertificates: function(hospitalId, onSnapshot) {
        return firebase.firestore()
          .collection("requestCertificates")
          .where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "asc")
          .onSnapshot(onSnapshot);
      },
      loadRequestCertificatesByHospitalWork: async function(hospitalWorkId) {
        let itemList = [];
        await firebase.firestore()
          .collection("requestCertificates")
          .where("hospitalWorkId", "==", hospitalWorkId)
          .where("state", "==", "accepted")
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadRequestCertificatesByHospital: async function(hospitalId, createAtStart, createAtEnd) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("requestCertificates")
          .where("hospitalId", "==", hospitalId)
          .where("state", "==", "accepted");
        if ((createAtStart != null) && (createAtEnd != null)) {
          ref = ref.where("createAt", ">=", createAtStart)
            .where("createAt", "<=", createAtEnd);
        }
        await ref.orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadRequestCertificate: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("requestCertificates")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addRequestCertificate: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("requestCertificates")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editRequestCertificate: async function(id, model) {
        await firebase.firestore()
          .collection("requestCertificates")
          .doc(id)
          .update(model);
      },
      deleteRequestCertificate: async function(id) {
        await firebase.firestore()
          .collection("requestCertificates")
          .doc(id)
          .delete();
      },
      setRequestCertificate: async function(id, model) {
        await firebase.firestore()
          .collection("requestCertificates")
          .doc(id)
          .set(model);
      },
      onMembershipUsages: function(onSnapshot) {
        return firebase.firestore()
          .collection("membershipUsages")
          .onSnapshot(onSnapshot);
      },
      loadMembershipUsages: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("membershipUsages")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadMembershipUsagesThisMonth: async function(hospitalId) {
        let thisMonth = new Date();
        thisMonth.setDate(1);
        thisMonth.setHours(0, 0, 0, 0);
        let nextMonth = new Date(thisMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        let itemList = [];
        await firebase.firestore()
          .collection("membershipUsages")
          .where("hospitalId", "==", hospitalId)
          .where("month", ">=", thisMonth)
          .where("month", "<", nextMonth)
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadMembershipUsage: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("membershipUsages")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addMembershipUsageForPushCount: async function(hospital, pushCount) {
        let thisMonth = new Date();
        thisMonth.setDate(1);
        thisMonth.setHours(0, 0, 0, 0);
        let nextMonth = new Date(thisMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        let thisMonthTimestamp = firebase.firestore.Timestamp.fromDate(thisMonth);
        //let nextMonthTimestamp = firebase.firestore.Timestamp.fromDate(nextMonth);
        let addModel = {};
        addModel.hospitalId = hospital.id;
        addModel.month = thisMonthTimestamp;
        addModel.pushCount = pushCount;
        addModel.normalClinicCount = 0;
        addModel.normalClinicSeconds = 0;
        addModel.audioClinicCount = 0;
        addModel.audioClinicSeconds = 0;
        addModel.videoClinicCount = 0;
        addModel.videoClinicSeconds = 0;
        await firebase.firestore()
          .collection("membershipUsages")
          .add(addModel);
      },
      editMembershipUsage: async function(id, model) {
        await firebase.firestore()
          .collection("membershipUsages")
          .doc(id)
          .update(model);
      },
      editMembershipUsageClinicSeconds: async function(hospital, clinicType, clinicSeconds) {
        let thisMonth = new Date();
        thisMonth.setDate(1);
        thisMonth.setHours(0, 0, 0, 0);
        let nextMonth = new Date(thisMonth);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        let thisMonthTimestamp = firebase.firestore.Timestamp.fromDate(thisMonth);
        let nextMonthTimestamp = firebase.firestore.Timestamp.fromDate(nextMonth);
        await firebase.firestore()
          .collection("membershipUsages")
          .where("hospitalId", "==", hospital.id)
          .where("month", ">=", thisMonthTimestamp)
          .where("month", "<", nextMonthTimestamp)
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              let ref = firebase.firestore()
                .collection("membershipUsages").doc(querySnapshot.docs[0].id);
              await firebase.firestore().runTransaction(async (transaction) => {
                await transaction.get(ref).then((documentSnapshot) => {
                  if (documentSnapshot.exists) {
                    let membershipUsage = documentSnapshot.data();
                    if (clinicType == "ClinicType.normal") {
                      let newSeconds = membershipUsage.normalClinicSeconds + clinicSeconds;
                      if (hospital.subscriptionPlan.clinicTimeUnlimited == false) {
                        if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
                          let hospitalRef = firebase.firestore()
                            .collection("hospitals")
                            .doc(hospital.id);
                          transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
                        }
                      }
                      transaction.update(ref, {
                        normalClinicSeconds: newSeconds,
                        normalClinicCount: membershipUsage.normalClinicCount + 1
                      });
                    } else if (clinicType == "ClinicType.video") {
                      let newSeconds = membershipUsage.videoClinicSeconds + clinicSeconds;
                      if (hospital.subscriptionPlan.clinicTimeUnlimited == false) {
                        if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
                          let hospitalRef = firebase.firestore()
                            .collection("hospitals")
                            .doc(hospital.id);
                          transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
                        }
                      }
                      transaction.update(ref, {
                        videoClinicSeconds: membershipUsage.videoClinicSeconds + clinicSeconds,
                        videoClinicCount: membershipUsage.videoClinicCount + 1
                      });
                    } else if (clinicType == "ClinicType.audio") {
                      let newSeconds = membershipUsage.audioClinicSeconds + clinicSeconds;
                      if (hospital.subscriptionPlan.clinicTimeUnlimited == false) {
                        if (newSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
                          let hospitalRef = firebase.firestore()
                            .collection("hospitals")
                            .doc(hospital.id);
                          transaction.update(hospitalRef, { disableUntil: nextMonthTimestamp });
                        }
                      }
                      transaction.update(ref, {
                        audioClinicSeconds: membershipUsage.audioClinicSeconds + clinicSeconds,
                        audioClinicCount: membershipUsage.audioClinicCount + 1
                      });
                    }
                  }
                });
              });
            } else {
              let addModel = {};
              addModel.hospitalId = hospital.id;
              addModel.month = thisMonth;
              addModel.pushCount = 0;
              addModel.normalClinicCount = 0;
              addModel.normalClinicSeconds = 0;
              addModel.audioClinicCount = 0;
              addModel.audioClinicSeconds = 0;
              addModel.videoClinicCount = 0;
              addModel.videoClinicSeconds = 0;

              if (clinicType == "ClinicType.normal") {
                addModel.normalClinicCount = 1;
                addModel.normalClinicSeconds = clinicSeconds;
              } else if (clinicType == "ClinicType.video") {
                addModel.videoClinicCount = 1;
                addModel.videoClinicSeconds = clinicSeconds;
              } else if (clinicType == "ClinicType.audio") {
                addModel.audioClinicCount = 1;
                addModel.audioClinicSeconds = clinicSeconds;
              }

              await firebase.firestore()
                .collection("membershipUsages")
                .add(addModel);

              if (hospital.subscriptionPlan.clinicTimeUnlimited == false) {
                if (clinicSeconds >= hospital.subscriptionPlan.clinicTimeLimit) {
                  await firebase.firestore()
                    .collection("hospitals")
                    .doc(hospital.id)
                    .update({ disableUntil: nextMonthTimestamp });
                }
              }
            }
          });
      },
      deleteMembershipUsage: async function(id) {
        await firebase.firestore()
          .collection("membershipUsages")
          .doc(id)
          .delete();
      },
      setMembershipUsage: async function(id, model) {
        await firebase.firestore()
          .collection("membershipUsages")
          .doc(id)
          .set(model);
      },
      quit: async function(hospitalId, whyQuit) {
        let hospitalRef = firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId);
        await firebase.firestore().runTransaction(async (transaction) => {
          await transaction.get(hospitalRef).then(async () => {
            await firebase.firestore()
              .collection("hospitalSubscriptions")
              .where("hospitalId", "==", hospitalId)
              .get()
              .then(async (subscriptionsSnapshot) => {
                await firebase.firestore()
                  .collection("hospitalPayments")
                  .where("hospitalId", "==", hospitalId)
                  .get()
                  .then((paymentsSnapshot) => {
                    for (let i = 0; i < subscriptionsSnapshot.docs.length; i++) {
                      let subscription = subscriptionsSnapshot.docs[i].data();
                      if (subscription.active == true) {
                        transaction.update(subscriptionsSnapshot.docs[i].ref, { active: false });
                      }
                    }
                    for (let i = 0; i < paymentsSnapshot.docs.length; i++) {
                      let payment = paymentsSnapshot.docs[i].data();
                      let now = new Date();
                      if ((payment.scheduledAt != undefined) && (payment.scheduledAt != null)) {
                        if (payment.scheduledAt.toDate() > now) {
                          transaction.delete(paymentsSnapshot.docs[i].ref);
                        }
                      }
                    }
                    transaction.update(hospitalRef, {
                      whyQuit: whyQuit,
                      quit: true,
                      quitDate: firebase.firestore.Timestamp.now()
                      //planUntilDate: planUntilDate,
                    });
                  });
              });
          });
        });
      },
      deleteHospitalPaymentsForUserDelete: async function(hospitalId) {
        let hospitalRef = firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId);
        await firebase.firestore().runTransaction(async (transaction) => {
          await transaction.get(hospitalRef).then(async () => {
            await firebase.firestore()
              .collection("hospitalSubscriptions")
              .where("hospitalId", "==", hospitalId)
              .get()
              .then(async (subscriptionsSnapshot) => {
                await firebase.firestore()
                  .collection("hospitalPayments")
                  .where("hospitalId", "==", hospitalId)
                  .get()
                  .then((paymentsSnapshot) => {
                    for (let i = 0; i < subscriptionsSnapshot.docs.length; i++) {
                      let subscription = subscriptionsSnapshot.docs[i].data();
                      if (subscription.active == true) {
                        transaction.update(subscriptionsSnapshot.docs[i].ref, { active: false });
                      }
                    }
                    for (let i = 0; i < paymentsSnapshot.docs.length; i++) {
                      let payment = paymentsSnapshot.docs[i].data();
                      let now = new Date();
                      if ((payment.scheduledAt != undefined) && (payment.scheduledAt != null)) {
                        if (payment.scheduledAt.toDate() > now) {
                          transaction.delete(paymentsSnapshot.docs[i].ref);
                        }
                      }
                    }
                  });
              });
          });
        });
      },
      async changeCard(subscriptionId, result) {
        await firebase.firestore().runTransaction(async (transaction) => {
          let subscriptionRef = firebase.firestore().collection("hospitalSubscriptions").doc(subscriptionId);
          return transaction.get(subscriptionRef).then(async (doc) => {
            if (doc.exists) {
              let subscription = doc.data();
              await firebase.firestore().collection("hospitalPayments")
                .where("hospitalId", "==", subscription.hospitalId)
                .where("paid", "==", false)
                .where("scheduledAt", ">=", firebase.firestore.Timestamp.now())
                .get()
                .then((querySnapshot) => {
                  if (querySnapshot.docs.length > 0) {
                    let ref = firebase.firestore().collection("hospitalPayments").doc(querySnapshot.docs[0].id);
                    transaction.update(ref, {
                      subscription: subscription,
                      subscriptionId: subscriptionId
                    });
                  }
                });

              transaction.update(subscriptionRef, {
                billingKey: result.PCD_PAYER_ID,
                cardName: result.PCD_PAY_CARDNAME,
                cardNumber: result.PCD_PAY_CARDNUM
              });

              if (subscription.hospitalId != null) {
                let hospitalRef = firebase.firestore().collection("hospitals").doc(subscription.hospitalId);
                transaction.update(hospitalRef, {
                  subscriptionId: subscription.id,
                  billingKey: result.PCD_PAYER_ID,
                  cardName: result.PCD_PAY_CARDNAME,
                  cardNumber: result.PCD_PAY_CARDNUM
                });
              }
            }
          });
        });
      },
      async changeCardError(subscriptionId, errorCode, errorMessage) {
        await firebase.firestore()
          .collection("hospitalSubscriptions")
          .doc(subscriptionId)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              let subscription = doc.data();
              await firebase.firestore()
                .collection("hospitalSubscriptions")
                .doc(subscription.prevSubscriptionId)
                .update({
                  active: true
                });

              await firebase.firestore()
                .collection("hospitalSubscriptions")
                .doc(subscriptionId)
                .update({
                  errorCode: errorCode,
                  errorMessage: errorMessage,
                  active: false
                });
            }
          });
      },
      onCoupons: function(onSnapshot) {
        return firebase.firestore()
          .collection("coupons")
          .onSnapshot(onSnapshot);
      },
      loadCoupons: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("coupons")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadCoupon: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("coupons")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      loadCouponByNumber: async function(number) {
        let item = null;
        await firebase.firestore()
          .collection("coupons")
          .where("number", "==", number)
          .get()
          .then(async (querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              item = querySnapshot.docs[0].data();
            }
          });
        return item;
      },
      addCoupon: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("coupons")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editCoupon: async function(id, model) {
        await firebase.firestore()
          .collection("coupons")
          .doc(id)
          .update(model);
      },
      deleteCoupon: async function(id) {
        await firebase.firestore()
          .collection("coupons")
          .doc(id)
          .delete();
      },
      setCoupon: async function(id, model) {
        await firebase.firestore()
          .collection("coupons")
          .doc(id)
          .set(model);
      },
      saveStatistics: async function() {
        //console.log('saveStatistics');
        let now = new Date();
        let dayDocId = moment(now).format("YYYYMMDD");
        let yearDocId = moment(now).format("YYYY");
        //console.log('dayDocId', dayDocId);
        //console.log('yearDocId', yearDocId);

        await firebase.firestore().runTransaction(async (transaction) => {
          let dayDocRef = firebase.firestore()
            .collection("hospitalStatistics")
            .doc("hospitalStatistics")
            .collection("dayStatistics")
            .doc(dayDocId);

          let yearDocRef = firebase.firestore()
            .collection("hospitalStatistics")
            .doc("hospitalStatistics")
            .collection("yearStatistics")
            .doc(yearDocId);

          return transaction.get(dayDocRef).then(async (dayDoc) => {
            await transaction.get(yearDocRef).then(async (yearDoc) => {
              let dayKey = now.getHours() + "";
              //console.log('dayKey', dayKey);
              let dayUpdateData = {};
              if (dayDoc.exists) {
                //console.log('dayDoc exists');
                let dayData = dayDoc.data();
                if ((dayData[dayKey] != null) && (dayData[dayKey] != undefined)) {
                  dayUpdateData[dayKey] = dayData[dayKey] + 1;
                } else {
                  dayUpdateData[dayKey] = 1;
                }
                dayUpdateData["sum"] = dayData["sum"] + 1;
                //console.log('dayUpdateData', dayUpdateData);
                transaction.update(dayDocRef, dayUpdateData);
              } else {
                //console.log('dayDoc not exists');
                dayUpdateData["createAt"] = firebase.firestore.Timestamp.fromDate(now);
                dayUpdateData["sum"] = 1;
                dayUpdateData[dayKey] = 1;
                //console.log('dayUpdateData', dayUpdateData);
                transaction.set(dayDocRef, dayUpdateData);
              }

              let yearKey = now.getMonth() + 1 + "";
              //console.log('yearKey', yearKey);
              let yearUpdateData = {};
              if (yearDoc.exists) {
                //console.log('yearDoc.exists');
                let yearData = yearDoc.data();
                if ((yearData[yearKey] != null) && (yearData[yearKey] != undefined)) {
                  yearUpdateData[yearKey] = yearData[yearKey] + 1;
                } else {
                  yearUpdateData[yearKey] = 1;
                }
                //console.log('yearDocRef', yearUpdateData);
                transaction.update(yearDocRef, yearUpdateData);
              } else {
                //console.log('yearDoc.exists == false');
                yearUpdateData["createAt"] = firebase.firestore.Timestamp.fromDate(now);
                yearUpdateData[yearKey] = 1;
                //console.log('yearDocRef', yearUpdateData);
                transaction.set(yearDocRef, yearUpdateData);
              }
            });
          });
        });
      },
      onPopups: function(onSnapshot) {
        return firebase.firestore()
          .collection("popups")
          .onSnapshot(onSnapshot);
      },
      loadPopups: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("popups")
          .where("sites", "array-contains", "병원웹")
          .orderBy("createAt", "asc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPopup: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("popups")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPopup: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("popups")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPopup: async function(id, model) {
        await firebase.firestore()
          .collection("popups")
          .doc(id)
          .update(model);
      },
      deletePopup: async function(id) {
        await firebase.firestore()
          .collection("popups")
          .doc(id)
          .delete();
      },
      setPopup: async function(id, model) {
        await firebase.firestore()
          .collection("popups")
          .doc(id)
          .set(model);
      },
      onHospitalQnas: function(hospitalId, isReplied, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalQnas")
          .where("hospitalId", "==", hospitalId)
          .where("isReplied", "==", isReplied)
          .orderBy("createAt", "desc")
          .onSnapshot(onSnapshot);
      },
      loadHospitalQnas: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalQnas")
          .where("hospitalId", "==", hospitalId)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalQna: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalQnas")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalQna: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalQnas")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospitalQna: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalQnas")
          .doc(id)
          .update(model);
      },
      deleteHospitalQna: async function(id) {
        await firebase.firestore()
          .collection("hospitalQnas")
          .doc(id)
          .delete();
      },
      setHospitalQna: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalQnas")
          .doc(id)
          .set(model);
      },
      onHospitalWorkUpdateLogs: function(hospitalWorkId, onSnapshot) {
        return firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .onSnapshot(onSnapshot);
      },
      loadHospitalWorkUpdateLogs: async function(hospitalWorkId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalWorkUpdateLog: async function(hospitalWorkId, id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalWorkUpdateLog: async function(hospitalWorkId, model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospitalWorkUpdateLog: async function(hospitalWorkId, id, model) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .doc(id)
          .update(model);
      },
      deleteHospitalWorkUpdateLog: async function(hospitalWorkId, id) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .doc(id)
          .delete();
      },
      setHospitalWorkUpdateLog: async function(hospitalWorkId, id, model) {
        await firebase.firestore()
          .collection("hospitalWorks")
          .doc(hospitalWorkId)
          .collection("hospitalWorkUpdateLogs")
          .doc(id)
          .set(model);
      },
      findBusinessNumber: async function(businessNumber) {
        let result = false;
        await firebase.firestore()
          .collection("hospitals")
          .where("businessNumber", "==", businessNumber)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              result = true;
            }
          });
        return result;
      },
      onAdminNotifications: function(onSnapshot) {
        return firebase.firestore()
          .collection("adminNotifications")
          .onSnapshot(onSnapshot);
      },
      loadAdminNotifications: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("adminNotifications")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadAdminNotification: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("adminNotifications")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addAdminNotification: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("adminNotifications")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editAdminNotification: async function(id, model) {
        await firebase.firestore()
          .collection("adminNotifications")
          .doc(id)
          .update(model);
      },
      deleteAdminNotification: async function(id) {
        await firebase.firestore()
          .collection("adminNotifications")
          .doc(id)
          .delete();
      },
      setAdminNotification: async function(id, model) {
        await firebase.firestore()
          .collection("adminNotifications")
          .doc(id)
          .set(model);
      },
      onHospitalPushNotifications: function(onSnapshot) {
        return firebase.firestore()
          .collection("hospitalPushNotifications")
          .onSnapshot(onSnapshot);
      },
      loadHospitalPushNotifications: async function() {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalPushNotifications")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadHospitalPushNotification: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitalPushNotifications")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addHospitalPushNotification: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("hospitalPushNotifications")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editHospitalPushNotification: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalPushNotifications")
          .doc(id)
          .update(model);
      },
      deleteHospitalPushNotification: async function(id) {
        await firebase.firestore()
          .collection("hospitalPushNotifications")
          .doc(id)
          .delete();
      },
      setHospitalPushNotification: async function(id, model) {
        await firebase.firestore()
          .collection("hospitalPushNotifications")
          .doc(id)
          .set(model);
      },
      onReservedPushNotifications: function(onSnapshot) {
        return firebase.firestore()
          .collection("reservedPushNotifications")
          .onSnapshot(onSnapshot);
      },
      loadReservedPushNotifications: async function(hospitalId, reservedAtStart, reservedAtEnd, sendMethodFilter, sendedFilter) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("reservedPushNotifications")
          .where("senderType", "==", "hospital")
          .where("senderId", "==", hospitalId);
        if (reservedAtStart != null) {
          ref = ref.where("reservedAt", ">=", reservedAtStart);
        }
        if (reservedAtEnd != null) {
          ref = ref.where("reservedAt", "<=", reservedAtEnd);
        }
        if (sendMethodFilter != null) {
          for (let i = 0; i < sendMethodFilter.length; i++) {
            ref = ref.where("sendMethods", "array-contains", sendMethodFilter[i]);
          }
        }
        if (sendedFilter != null) {
          ref = ref.where("sended", "==", sendedFilter);
        }
        await ref.orderBy("reservedAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadReservedPushNotificationsStatistics: async function(hospitalId, startTimestamp, endTimestamp) {
        let itemList = [];
        await firebase.firestore()
          .collection("reservedPushNotifications")
          .where("senderType", "==", "hospital")
          .where("senderId", "==", hospitalId)
          .where("sended", "==", true)
          .where("sendedAt", ">=", startTimestamp)
          .where("sendedAt", "<=", endTimestamp)
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadReservedPushNotification: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("reservedPushNotifications")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      loadReservedPushNotificationsByHospitalWorkId: async function(hospitalWorkId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("reservedPushNotifications")
          .where("hospitalWorkId", "==", hospitalWorkId)
          .where("sended", "==", false)
          .orderBy("reservedAt", "asc");
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      addReservedPushNotification: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("reservedPushNotifications")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editReservedPushNotification: async function(id, model) {
        await firebase.firestore()
          .collection("reservedPushNotifications")
          .doc(id)
          .update(model);
      },
      deleteReservedPushNotification: async function(id) {
        await firebase.firestore()
          .collection("reservedPushNotifications")
          .doc(id)
          .delete();
      },
      setReservedPushNotification: async function(id, model) {
        await firebase.firestore()
          .collection("reservedPushNotifications")
          .doc(id)
          .set(model);
      },
      loadHospitalMileagePayments: async function(hospitalId) {
        let itemList = [];
        await firebase.firestore()
          .collection("hospitalPayments")
          .where("hospitalId", "==", hospitalId)
          .where("mileagePayment", "==", true)
          .orderBy("createAt", "desc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      onPaymentCards: function(onSnapshot) {
        return firebase.firestore()
          .collection("paymentCards")
          .onSnapshot(onSnapshot);
      },
      loadPaymentCards: async function(userId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("paymentCards")
          .where("userId", "==", userId)
          .where("isOk", "==", true);
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPaymentCard: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("paymentCards")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPaymentCard: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("paymentCards")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPaymentCard: async function(id, model) {
        await firebase.firestore()
          .collection("paymentCards")
          .doc(id)
          .update(model);
      },
      deletePaymentCard: async function(id) {
        await firebase.firestore()
          .collection("paymentCards")
          .doc(id)
          .delete();
      },
      setPaymentCard: async function(id, model) {
        await firebase.firestore()
          .collection("paymentCards")
          .doc(id)
          .set(model);
      },
      onPatientInfos: function(hospitalId, onSnapshot) {
        return firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .onSnapshot(onSnapshot);
      },
      loadPatientInfos: async function(hospitalId, patientId, familyPatient, limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .where("familyPatient", "==", familyPatient)
          .where("patientId", "==", patientId);
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPatientInfo: async function(hospitalId, id) {
        let item = null;
        await firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPatientInfo: async function(hospitalId, model) {
        let newDocRef = firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPatientInfo: async function(hospitalId, id, model) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .doc(id)
          .update(model);
      },
      deletePatientInfo: async function(hospitalId, id) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .doc(id)
          .delete();
      },
      setPatientInfo: async function(hospitalId, id, model) {
        await firebase.firestore()
          .collection("hospitals")
          .doc(hospitalId)
          .collection("patientInfos")
          .doc(id)
          .set(model);
      },
      onFamilys: function(onSnapshot) {
        return firebase.firestore()
          .collection("familys")
          .onSnapshot(onSnapshot);
      },
      loadFamilys: async function(appUserId) {
        let itemList = [];
        await firebase.firestore()
          .collection("familys")
          .where("appUserId", "==", appUserId)
          .orderBy("createAt", "asc")
          .get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadFamily: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("familys")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addFamily: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("familys")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editFamily: async function(id, model) {
        await firebase.firestore()
          .collection("familys")
          .doc(id)
          .update(model);
      },
      deleteFamily: async function(id) {
        await firebase.firestore()
          .collection("familys")
          .doc(id)
          .delete();
      },
      setFamily: async function(id, model) {
        await firebase.firestore()
          .collection("familys")
          .doc(id)
          .set(model);
      },
      loadHospitalWorksByDoctorId: async function(
        hospitalId,
        doctorId) {
        let itemList = [];
        let ref = firebase.firestore().collection("hospitalWorks")
          .where("hospitalId", "==", hospitalId)
          .where("doctorId", "==", doctorId);
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      onTempHolidays: function(onSnapshot) {
        return firebase.firestore()
          .collection("tempHolidays")
          .onSnapshot(onSnapshot);
      },
      loadTempHolidays: async function(hospitalId, limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("tempHolidays")
          .where("hospitalId", "==", hospitalId)
          .orderBy("date", "asc");
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadTempHolidaysOfHospitalWorker: async function(hospitalWorkerId, limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("tempHolidays")
          .where("hospitalWorkerId", "==", hospitalWorkerId)
          .orderBy("date", "asc");
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadTempHoliday: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("tempHolidays")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addTempHoliday: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("tempHolidays")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editTempHoliday: async function(id, model) {
        await firebase.firestore()
          .collection("tempHolidays")
          .doc(id)
          .update(model);
      },
      deleteTempHoliday: async function(id) {
        await firebase.firestore()
          .collection("tempHolidays")
          .doc(id)
          .delete();
      },
      setTempHoliday: async function(id, model) {
        await firebase.firestore()
          .collection("tempHolidays")
          .doc(id)
          .set(model);
      },
      onPharmacys: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("pharmacys")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadPharmacys: async function(limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("pharmacys")
          .where("approveState", "==", "ApproveState.approved");
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.orderBy("name", "asc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPharmacy: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("pharmacys")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPharmacy: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("pharmacys")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPharmacy: async function(id, model) {
        await firebase.firestore()
          .collection("pharmacys")
          .doc(id)
          .update(model);
      },
      deletePharmacy: async function(id) {
        await firebase.firestore()
          .collection("pharmacys")
          .doc(id)
          .delete();
      },
      setPharmacy: async function(id, model) {
        await firebase.firestore()
          .collection("pharmacys")
          .doc(id)
          .set(model);
      },
      onDocuments: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("documents")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadDocuments: async function(hospitalWorkId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("documents")
          .where("hospitalWorkId", "==", hospitalWorkId);
        await ref.orderBy("createAt", "asc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadDocument: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("documents")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addDocument: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("documents")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
        return addModel.id;
      },
      editDocument: async function(id, model) {
        await firebase.firestore()
          .collection("documents")
          .doc(id)
          .update(model);
      },
      deleteDocument: async function(id) {
        await firebase.firestore()
          .collection("documents")
          .doc(id)
          .delete();
      },
      setDocument: async function(id, model) {
        await firebase.firestore()
          .collection("documents")
          .doc(id)
          .set(model);
      },
      onSignUpCodeUsers: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("signUpCodeUsers")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadSignUpCodeUsers: async function(limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("signUpCodeUsers");
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.orderBy("createAt", "desc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadSignUpCodeUser: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("signUpCodeUsers")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addSignUpCodeUser: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("signUpCodeUsers")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editSignUpCodeUser: async function(id, model) {
        await firebase.firestore()
          .collection("signUpCodeUsers")
          .doc(id)
          .update(model);
      },
      deleteSignUpCodeUser: async function(id) {
        await firebase.firestore()
          .collection("signUpCodeUsers")
          .doc(id)
          .delete();
      },
      setSignUpCodeUser: async function(id, model) {
        await firebase.firestore()
          .collection("signUpCodeUsers")
          .doc(id)
          .set(model);
      },
      onSignUpCodeChannels: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("signUpCodeChannels")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadSignUpCodeChannels: async function(signUpCodeUserId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("signUpCodeChannels")
          .where("signUpCodeUserId", "==", signUpCodeUserId);
        await ref.orderBy("createAt", "desc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadSignUpCodeChannel: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("signUpCodeChannels")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      loadSignUpCodeChannelsBySignUpCode: async function(signUpCode) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("signUpCodeChannels")
          .where("signUpCode", "==", signUpCode);
        await ref.orderBy("createAt", "desc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      addSignUpCodeChannel: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("signUpCodeChannels")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editSignUpCodeChannel: async function(id, model) {
        await firebase.firestore()
          .collection("signUpCodeChannels")
          .doc(id)
          .update(model);
      },
      deleteSignUpCodeChannel: async function(id) {
        await firebase.firestore()
          .collection("signUpCodeChannels")
          .doc(id)
          .delete();
      },
      setSignUpCodeChannel: async function(id, model) {
        await firebase.firestore()
          .collection("signUpCodeChannels")
          .doc(id)
          .set(model);
      },
      onPartnerPointTransfers: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("partnerPointTransfers")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadPartnerPointTransfers: async function(limit) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("partnerPointTransfers");
        if (limit != null) {
          ref = ref.limit(limit);
        }
        await ref.orderBy("createAt", "desc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPartnerPointTransfer: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("partnerPointTransfers")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPartnerPointTransfer: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("partnerPointTransfers")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPartnerPointTransfer: async function(id, model) {
        await firebase.firestore()
          .collection("partnerPointTransfers")
          .doc(id)
          .update(model);
      },
      deletePartnerPointTransfer: async function(id) {
        await firebase.firestore()
          .collection("partnerPointTransfers")
          .doc(id)
          .delete();
      },
      setPartnerPointTransfer: async function(id, model) {
        await firebase.firestore()
          .collection("partnerPointTransfers")
          .doc(id)
          .set(model);
      },
      onPartnerPointUsages: function(onSnapshot, limit, startAfter, endBefore, startAt, orderBy, desc) {
        let ref = firebase.firestore()
          .collection("partnerPointUsages")
          .orderBy((orderBy != null) ? orderBy : "createAt", desc ? "desc" : "asc");
        if (startAfter != null) {
          ref = ref.startAfter(startAfter);
        }
        if (startAt != null) {
          ref = ref.startAt(startAt);
        }
        if (endBefore != null) {
          ref = ref.endBefore(endBefore);
        }
        if (limit != null) {
          ref = ref.limit(limit);
        }
        return ref.onSnapshot(onSnapshot);
      },
      loadPartnerPointUsagesByPartnerId: async function(partnerId) {
        let itemList = [];
        let ref = firebase.firestore()
          .collection("partnerPointUsages")
          .where("partnerId", "==", partnerId);
        await ref.orderBy("createAt", "desc").get()
          .then(async (querySnapshot) => {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let itemInfo = querySnapshot.docs[i].data();
              itemInfo.id = querySnapshot.docs[i].id;
              itemList.push(itemInfo);
            }
          });
        return itemList;
      },
      loadPartnerPointUsage: async function(id) {
        let item = null;
        await firebase.firestore()
          .collection("partnerPointUsages")
          .doc(id)
          .get()
          .then(async (documentSnapshot) => {
            if (documentSnapshot.exists) {
              item = documentSnapshot.data();
            }
          });
        return item;
      },
      addPartnerPointUsage: async function(model) {
        let newDocRef = firebase.firestore()
          .collection("partnerPointUsages")
          .doc();
        let addModel = model;
        addModel.id = newDocRef.id;
        await newDocRef.set(addModel);
      },
      editPartnerPointUsage: async function(id, model) {
        await firebase.firestore()
          .collection("partnerPointUsages")
          .doc(id)
          .update(model);
      },
      deletePartnerPointUsage: async function(id) {
        await firebase.firestore()
          .collection("partnerPointUsages")
          .doc(id)
          .delete();
      },
      setPartnerPointUsage: async function(id, model) {
        await firebase.firestore()
          .collection("partnerPointUsages")
          .doc(id)
          .set(model);
      }
    };
  }
};

Vue.use(database);
