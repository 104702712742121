import Vue from "vue";
import VueRouter from "vue-router";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import DashboardLayout from "../views/Layout/DashboardLayout";
import '../plugins/firebase';
import firebase from 'firebase/compat/app';

// Dashboard pages
const SignIn = () => import("../views/Auth/SignIn.vue");
const SignUp = () => import("../views/Auth/SignUp.vue");
const FindPw = () => import("../views/Auth/FindPw.vue");
const MyInfo = () => import("../views/Dashboard/MyInfo.vue");
const ChangePw = () => import("../views/Dashboard/ChangePw.vue");
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: "Auth",
    redirect: "/pages/signin",
    component: AuthBasicLayout,
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: "/pages/signin",
        name: "로그인",
        component: SignIn,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/signup",
        name: "회원가입",
        component: SignUp,
        props: true,
        meta: {
          requireAuth: false
        },
      },
      {
        path: "/pages/findpw",
        name: "비밀번호 찾기",
        component: FindPw,
        meta: {
          requireAuth: false
        },
      },
    ],
  },
  {
    path: '/',
    name: "Dashboard",
    redirect: "/pages/dashboard",
    component: DashboardLayout,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: "/pages/dashboard",
        name: "대시보드",
        component: Dashboard,
        meta: {
          requireAuth: true
        },
        props: true,
      },
      {
        path: "/pages/my-info",
        name: "마이페이지",
        component: MyInfo,
        meta: {
          requireAuth: true
        },
      },
      {
        path: "/pages/changepw",
        name: "비밀번호 변경",
        component: ChangePw,
        meta: {
          requireAuth: true
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let nextPath = null;
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (firebase.auth().currentUser == null) {
      nextPath = '/pages/signin';
    }
  }

  if (nextPath == null) {
    next();
  }
  else {
    next({ path: nextPath });
  }
});

export default router;
