import Vue from "vue";
import firebase from 'firebase/compat/app';
import axios from "axios";

export default {
};

export const functions = {
  install(Vue) {
    Vue.prototype.$functions = {
      call: async function (name, data) {
        var result = '';
        try {
          var callable = firebase.functions().httpsCallable(name);
          result = await callable(data);
        }
        catch (e) {
          console.log(e);
        }
        return result;
      },
      sendKakao: async function (data) {
        // kakao 
        await axios.post('https://us-central1-doctor365-9a68d.cloudfunctions.net/sendKakao', JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async function (response) {
            console.log('sendKakao:', response);
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    }
  }
};

Vue.use(functions);