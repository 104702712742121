<template>
<v-app>
    <v-app-bar
        app
        absolute
        height="100"
        class="bg-primary"
        flat>
        <div style="width:200px">
            <v-img src="@/assets/logo4.png" :width="200"></v-img>
        </div>
        <v-spacer></v-spacer>
        <div class="text-end">
            <div v-if="$auth.currentUser()">
                <div class="text-sm text-white">{{ name }}님, 환영합니다.</div>
                <div class="d-flex justify-center align-center">
                    <div
                        class="text-sm font-weight-bold text-white"
                        style="cursor: pointer;"
                        @click="$router.push({ name: '대시보드' })">홈</div>
                    <div class="mx-2 text-sm text-white">|</div>
                    <div
                        class="text-sm font-weight-bold text-white"
                        style="cursor: pointer;"
                        @click="$router.push({ name: '마이페이지' })">마이페이지</div>
                    <div class="mx-2 text-sm text-white">|</div>
                    <div
                        class="text-sm font-weight-bold text-white"
                        style="cursor: pointer;"
                        @click="$router.push({ name: '비밀번호 변경' })">비밀번호 변경</div>
                    <div class="mx-2 text-sm text-white">|</div>
                    <div
                        class="text-sm font-weight-bold text-white"
                        style="cursor: pointer;"
                        @click="$auth.logout();$router.push({ name: '로그인' })">로그아웃</div>
                </div>
            </div>
            <div v-else class="d-flex justify-center align-center">
                <div
                    class="text-sm font-weight-bold text-white"
                    style="cursor: pointer;"
                    @click="$auth.logout();$router.push({ name: '로그인' })">로그인</div>
                <div class="mx-2 text-sm text-white">|</div>
                <div
                    class="text-sm font-weight-bold text-white"
                    style="cursor: pointer;"
                    @click="$auth.logout();$router.push({ name: '회원가입' })">회원가입</div>
            </div>
        </div>
    </v-app-bar>

    <v-main app class="auth-pages">
        <fade-transition
            :duration="200"
            origin="center top"
            mode="out-in">
            <v-container class="pt-6">
                <router-view></router-view>
            </v-container>
        </fade-transition>
    </v-main>
    <div style="height: 200px">
    </div>
    <div class="py-4 px-8" style="background-color:#e9ecef">
        <div class="text-sm text-body">상호명 : 주식회사 플라이닥터</div>
        <div class="text-sm text-body">사업자등록번호 : 188-81-02379</div>
        <div class="text-sm text-body">대표자명 : 이동진</div>
        <div class="text-sm text-body">사업장주소지 : 서울특별시 강남구 테헤란로 521, 29층(삼성동, 파르나스타워)</div>
        <div class="text-sm text-body">통신판매업번호 : 2022-서울강남-04256</div>
        <div class="text-sm text-body">고객센터 : 1533-3965</div>
    </div>
</v-app>
</template>

<script>
import {
    FadeTransition
} from "vue2-transitions";

export default {
    name: "page-layout",
    components: {
        FadeTransition,
    },
    data() {
        return {
            paragraphs: "",
            tab: null,
            uid: "",
            password: "",
            bottomSheetMessage: "로그인 권한이 없습니다.",
            name: ""
        };
    },
    async mounted() {
        let authUser = this.$auth.currentUser();
        if (authUser) {
            try {
                let user = await this.$database.loadSignUpCodeUser(authUser.uid);
                this.name = user.name;
            } catch (e) {
                console.log(e);
            }
        }
    },
    methods: {
        headerTitle() {
            switch (this.$route.name) {
                case "SignUpBasic":
                    this.paragraphs =
                        "Use these awesome forms to login or create new account in your project for free.";
                    return "Welcome!";
                default:
                    break;
            }
        }
    },
};
</script>
